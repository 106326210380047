import React from 'react';

class PauseAtAdBreaks extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            checked: false
        };
        this.handleCheckbox = this.handleCheckbox.bind(this);
    }

    handleCheckbox = () => {
        const isChecked = !this.state.checked;
        this.setState({ checked: isChecked });
        if (this.props.onChange) {
            this.props.onChange(isChecked);
        }
    };

    render() {
        const { checked } = this.state;
        const classes = checked ? 'active btn-success' : 'btn-primary';
        return (
            <div>
                <div style={{ width: '100px' }}>
                    <button
                        className={`btn-sm btn btn-block ${classes}`}
                        type="button"
                        onClick={this.handleCheckbox}
                    >
                        {`Pause ${this.state.checked ? 'On' : 'Off'}`}
                    </button>
                </div>
            </div>
        );
    }
}

export default PauseAtAdBreaks;
