import React, { Component } from 'react';
import queryString from 'query-string';
import Login from './login';
import Reset from './reset';
import Verify from './verify';

export default class LandingPage extends Component {
    constructor(props) {
        super(props);
        const { email, token, type } = queryString.parse(
            window.location.search
        );
        this.state = {
            email,
            token,
            type
        };
    }

    render() {
        const { email, token, type } = this.state;

        return (
            <div>
                {(() => {
                    switch (type) {
                        case 'verify':
                            return <Verify />;
                        case 'reset':
                            return <Reset data={{ token, email }} />;
                        default:
                            return <Login email={email} />;
                    }
                })()}
            </div>
        );
    }
}
