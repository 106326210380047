module.exports = {
    getThumbUrl: (assets, defaultUri) => {
        let thumbUrl =
            defaultUri ||
            `https://www.thestoreformusic.com/shared/images/image-missing.png`;
        let wideThumb = null;
        let wideStill = null;

        if (assets) {
            assets.some(asset => {
                if (asset && asset.title && asset.title === '16x9') {
                    if (asset.type === 'artwork') {
                        wideThumb = asset;
                    } else if (asset.type === 'still') {
                        wideStill = asset;
                    }
                }
                return wideThumb;
            });
            if (wideThumb) {
                thumbUrl = wideThumb.uri;
            } else if (wideStill) {
                thumbUrl = wideStill.uri;
            }
        }

        return thumbUrl;
    }
};
