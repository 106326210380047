import client from '../Feathers';
import UniqueSelects from '../uiSchemas/unique-select-array';

const schemaCache = {};

const UISchemaLoader = async serviceName => {
    // Empty the local cache if the user logs out
    client.on('logout', () => {
        Object.keys(schemaCache).forEach(key => (schemaCache[key] = null));
    });

    if (schemaCache[serviceName]) {
        return schemaCache[serviceName];
    } else {
        const service = client.service('ui-schema');
        const uiSchema = await service.get(serviceName);

        if (serviceName === 'media' || serviceName === 'products') {
            uiSchema.genres['ui:ArrayFieldTemplate'] = UniqueSelects;
        }

        schemaCache[serviceName] = uiSchema;
        return uiSchema;
    }
};

export default UISchemaLoader;
