import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Navbar, Nav, NavDropdown, MenuItem } from 'react-bootstrap';
import client from '../../Feathers';

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isAuthed: false,
            user: null,
            groups: [],
            activeGroup: '',
            searchTerm: ''
        };
    }

    componentDidMount() {
        client.on('userSet', () => {
            const user = client.get('user');
            this.setState({
                isAuthed: true,
                user
            });
            if (user.supergroupId) {
                this.showChangeGroups();
            } else {
                this.getCurrentGroupTitle();
            }
        });

        client.on('logout', () => {
            this.setState({
                groups: [],
                isAuthed: false,
                user: null
            });
        });
    }

    showChangeGroups = () =>
        client
            .service('supergroups')
            .find()
            .then(
                client
                    .service('groups')
                    .find()
                    .then(res =>
                        this.setState(
                            { groups: res.data },
                            this.getCurrentGroupTitle
                        )
                    )
            )
            .catch(error => {
                this.setState({ groups: [] });
                console.error(error);
            });

    getCurrentGroupTitle = async () => {
        const { user, groups } = this.state;
        if (groups.length) {
            const userGroup = groups.find(group => group.id === user.groupId);
            if (userGroup) {
                this.setState({ activeGroup: userGroup.metadata.title });
            } else {
                this.setState({ activeGroup: '' });
            }
        } else {
            client
                .service('groups')
                .get(user.groupId)
                .then(res => this.setState({ activeGroup: res.metadata.title }))
                .catch(error => {
                    this.setState({ groups: [] });
                    console.error(error);
                });
        }
    };

    changeGroup = groupId => {
        const { user } = this.state;
        if (groupId !== user.groupId) {
            client
                .service('users')
                .patch(user.id, { groupId: groupId })
                .then((window.location = '/'))
                .catch(alert);
        }
    };

    render() {
        const { isAuthed, user, searchTerm } = this.state;
        const {
            history: { push },
            location: { pathname }
        } = this.props;
        const service =
            pathname && pathname.length ? pathname.split('/')[1] : '';
        const doShowSearch =
            service.length && service !== 'browse' && service !== 'batch';
        const groupOptions = this.state.groups.map(group => (
            <MenuItem key={group.id} onClick={() => this.changeGroup(group.id)}>
                {group.metadata.title}
            </MenuItem>
        ));
        if (this.state.groups.length) {
            groupOptions.push(<MenuItem divider key="divider" />);
        }

        if (!doShowSearch && searchTerm.length) {
            this.setState({ searchTerm: '' });
        }

        return (
            <Navbar staticTop>
                <Navbar.Header>
                    <Navbar.Brand>
                        {isAuthed ? (
                            <Link to="/">Browse</Link>
                        ) : (
                            <span>
                                Welcome to <span className="no-caps">MoM</span>{' '}
                                & <span className="no-caps">DAD</span>
                            </span>
                        )}
                    </Navbar.Brand>
                    <Navbar.Toggle />
                </Navbar.Header>
                {isAuthed ? (
                    <Navbar.Collapse>
                        {doShowSearch ? (
                            <Nav>
                                <Navbar.Form className="form-inline">
                                    <span
                                        className="input-group"
                                        style={{ width: '500px' }}
                                    >
                                        <input
                                            className="form-control"
                                            type="text"
                                            id="search"
                                            value={searchTerm}
                                            onChange={element =>
                                                this.setState({
                                                    searchTerm:
                                                        element.target.value
                                                })
                                            }
                                            onKeyPress={e => {
                                                if (e.key === 'Enter') {
                                                    e.preventDefault();
                                                    push(
                                                        `/${service}?search=${searchTerm}`
                                                    );
                                                }
                                            }}
                                        />
                                        <span className="input-group-btn">
                                            <button
                                                type="button"
                                                className="btn btn-default my-2 my-sm-0"
                                                onClick={() =>
                                                    push(
                                                        `/${service}?search=${searchTerm}`
                                                    )
                                                }
                                            >{`Search ${service}`}</button>
                                        </span>
                                    </span>
                                </Navbar.Form>
                            </Nav>
                        ) : null}
                        <Nav pullRight>
                            <NavDropdown
                                eventKey={2}
                                id="group-dropdown"
                                title={`${this.state.activeGroup}`}
                            >
                                {groupOptions}
                                <MenuItem
                                    eventKey={1}
                                    onClick={() =>
                                        client
                                            .logout()
                                            .then(() => push('/'))
                                            .catch(alert)
                                    }
                                >
                                    {`Logout ${user && user.email}`}
                                </MenuItem>
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                ) : null}
            </Navbar>
        );
    }
}

export default withRouter(Header);
