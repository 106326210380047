import React, { Component } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import ServiceOptions from '../ServiceOptions';
import ItemGalleryPage from '../ItemGallery/item-gallery-page';
import { IconButton, SingleList, MediaLists } from './components';
import { Row, Col } from 'react-bootstrap';
import helpers from '../helpers';

function reorder(list, startIndex, endIndex) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
}

export default class ItemLinker extends Component {
    constructor(props) {
        super(props);

        const customItemGalleryProps = {
            handleThumbnailClick: this.addItem
        };

        const noOp = param => {
            // do nothing
            console.log('no op on', param);
        };

        const { formContext } = props;
        const linkFromService = formContext.linkFrom;
        const linkToService = props.name;

        let requiresOrder = false;
        if (formContext.linkOptions && formContext.linkOptions[linkToService]) {
            if (formContext.linkOptions[linkToService].filter) {
                customItemGalleryProps.filter =
                    formContext.linkOptions[linkToService].filter;
            }

            if (formContext.linkOptions[linkToService].requiresOrder) {
                requiresOrder = true;
            }
        }

        const selectedItems = props.formData || [];
        const onUpdate = formContext.onUpdate || noOp;
        const linkFromServiceOptions = new ServiceOptions(
            linkFromService,
            'gallery'
        );
        const linkToServiceOptions = new ServiceOptions(
            linkToService,
            'gallery'
        );
        const itemGalleryProps = { ...this.props, ...customItemGalleryProps };

        this.state = {
            selectedItems,
            linkFromService,
            linkToService,
            onUpdate,
            linkFromServiceOptions,
            linkToServiceOptions,
            itemGalleryProps,
            requiresOrder,
            isGalleryVisible: false,
            seasonChosen: 0
        };
    }

    updateItems = items => {
        const { linkToService, onUpdate } = this.state;
        this.setState({ selectedItems: items });
        onUpdate({
            serviceName: linkToService,
            items: items
        });
    };

    addItem = item => {
        const { selectedItems } = this.state;

        // Don't add it if it's already there
        const isInList = selectedItems.some(
            selectedItem => item.id === selectedItem.id
        );
        if (!isInList) {
            selectedItems.push(item);
            this.updateItems(selectedItems);
        }
    };

    removeItem = itemId => {
        let { selectedItems } = this.state;
        selectedItems.some((item, index) => {
            if (item.id === itemId) {
                selectedItems.splice(index, 1);
                return true;
            }
            return false;
        });
        this.updateItems(selectedItems);
    };

    onDragStart = () => {
        // Add a little vibration if the browser supports it.
        if (window.navigator.vibrate) {
            window.navigator.vibrate(100);
        }
    };

    onDragEnd = result => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        const items = reorder(
            this.state.selectedItems,
            result.source.index,
            result.destination.index
        );

        this.updateItems(items);
    };

    setActiveFilter = filter => {
        const { seasonChosen } = this.state;
        if (seasonChosen !== filter) {
            this.setState({ seasonChosen: filter });
        }
    };

    render() {
        const { linkToService, linkFromService, selectedItems } = this.state;
        const {
            itemGalleryProps,
            isGalleryVisible,
            requiresOrder
        } = this.state;
        const seasonsEditableItems = [];
        let editableItems = [];

        if (linkToService === 'media' && linkFromService === 'products') {
            const seasons = {};
            let { seasonChosen } = this.state;
            let seasonNum = 0;

            selectedItems.forEach(item => {
                seasonNum = 0;
                if (item.metadata.series && item.metadata.series.seasonNumber) {
                    seasonNum = item.metadata.series.seasonNumber;
                    if (!seasons[seasonNum]) {
                        seasons[seasonNum] = [];
                    }
                }
                if (!seasons[seasonNum]) {
                    seasons[seasonNum] = [];
                }
                const orderNum = item.metadata.series.orderNumber;
                seasons[seasonNum][orderNum] = item;
            });
            if (!seasons[seasonChosen]) {
                seasonChosen = parseInt(Object.keys(seasons)[0]);
            }

            const filteredItems = seasons[seasonChosen] || [];
            Object.keys(seasons).forEach(seasonStr => {
                const season = parseInt(seasonStr, 10);
                let title = 'Season ' + season;
                if (seasonChosen === 0) {
                    title = 'Episodes \u00a0\u00a0\u00a0\u2192';
                } else if (season === seasonChosen) {
                    // if this is the chosen season, add an arrow next to the title
                    title += '\u00a0\u00a0\u00a0\u2192';
                }
                seasonsEditableItems.push({
                    title,
                    id: 'season-' + season,
                    filter: () => {
                        if (season !== 0) {
                            this.setActiveFilter(season);
                        }
                    }
                });
            });

            editableItems = filteredItems.map(item => {
                const thumbnail = helpers.getThumbUrl(item.metadata.assets);

                return {
                    thumbnail,
                    title: item.metadata.title,
                    id: item.id,
                    seasonNumber: item.metadata.series.seasonNumber,
                    orderNumber: item.metadata.series.orderNumber,
                    service: linkToService
                };
            });
        } else {
            editableItems = selectedItems.map(item => {
                const thumbnail = helpers.getThumbUrl(
                    item.metadata.assets,
                    item.metadata.imageUri
                );

                return {
                    thumbnail,
                    title: item.metadata.title,
                    id: item.id,
                    service: linkToService
                };
            });
        }

        return (
            <div style={{ border: '1px solid black' }}>
                <legend id={'legend-' + linkToService}>
                    {linkToService.charAt(0).toUpperCase() +
                        linkToService.slice(1)}
                </legend>
                <DragDropContext
                    onDragStart={this.onDragStart}
                    onDragEnd={this.onDragEnd}
                >
                    {requiresOrder && (
                        <h4 style={{ textAlign: 'center' }}>
                            Drag to set order:
                        </h4>
                    )}
                    {seasonsEditableItems.length ? (
                        <MediaLists
                            items={editableItems}
                            onRemoveItem={this.removeItem}
                            seasons={seasonsEditableItems}
                            dndEnabled={false}
                            {...this.props}
                        />
                    ) : (
                        <SingleList
                            items={editableItems}
                            onRemoveItem={this.removeItem}
                            dndEnabled={requiresOrder}
                            {...this.props}
                        />
                    )}
                </DragDropContext>
                <Row>
                    <Col xs={3} xsOffset={9} className="text-right">
                        <IconButton
                            type="info"
                            icon={isGalleryVisible ? 'minus' : 'plus'}
                            className="btn-add col-xs-12"
                            tabIndex="0"
                            onClick={() => {
                                this.setState({
                                    isGalleryVisible: !isGalleryVisible
                                });
                            }}
                        />
                    </Col>
                </Row>
                {isGalleryVisible && <ItemGalleryPage {...itemGalleryProps} />}
            </div>
        );
    }
}
