import React, { Component } from 'react';
import TimeFormat from 'hh-mm-ss';

export default class AdBreak extends Component {
    constructor(props) {
        super(props);

        let [hms, hour, minute, second, millisecond] = ['', '', '', '', ''];
        if (props.formData) {
            hms = TimeFormat.fromS(props.formData, 'hh:mm:ss.sss');
            const hmsSplit = hms.split(':');
            [hour, minute] = hmsSplit;

            const secondSplit = hmsSplit[2].split('.');
            [second, millisecond] = secondSplit;
        }

        this.state = {
            hms,
            hour,
            minute,
            second,
            millisecond,
            value: props.formData
        };
    }

    onChange(name) {
        return event => {
            const localState = { ...this.state };
            localState[name] = parseInt(event.target.value, 10);

            if (name !== 'millisecond') {
                localState[name] = localState[name].toString().padStart(2, '0');
            } else {
                localState[name] = localState[name].toString().padStart(3, '0');
            }

            const hour = localState.hour.toString().padStart(2, '0');
            const minute = localState.minute.toString().padStart(2, '0');
            const second = localState.second.toString().padStart(2, '0');
            const millisecond = localState.millisecond
                .toString()
                .padStart(3, '0');
            const newHMS =
                hour + ':' + minute + ':' + second + '.' + millisecond;

            localState.hms = newHMS;
            localState.value = TimeFormat.toMs(newHMS) / 1000;

            this.setState(localState, () =>
                this.props.onChange(this.state.value)
            );
        };
    }

    render() {
        const { hour, minute, second, millisecond } = this.state;
        return (
            <div>
                <input
                    type="text"
                    className="short-number"
                    placeholder="HH"
                    style={{ textAlign: 'right' }}
                    value={hour}
                    onChange={this.onChange('hour')}
                />
                :
                <input
                    type="text"
                    className="short-number"
                    placeholder="MM"
                    value={minute}
                    onChange={this.onChange('minute')}
                />
                :
                <input
                    type="text"
                    className="short-number"
                    placeholder="SS"
                    style={{ textAlign: 'right' }}
                    value={second}
                    onChange={this.onChange('second')}
                />
                .
                <input
                    type="text"
                    className="short-number"
                    placeholder="sss"
                    value={millisecond}
                    onChange={this.onChange('millisecond')}
                />
            </div>
        );
    }
}
