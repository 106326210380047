import React from 'react';
import styled from 'styled-components';
import { confirmAlert } from 'react-confirm-alert';
import { borderRadius, colors, grid } from './constants';

const Container = styled.div`
  border-radius: ${borderRadius}px;
  border: 1px solid grey;
  background-color: ${colors.white};
  box-shadow: 'none';
  padding: ${grid / 2}px ${grid}px;
  min-height: 40px;
  margin-bottom: ${grid}px;
  user-select: none;

  /* anchor overrides */
  color: ${colors.black};

  &:hover,
  &:active {
    color: ${colors.black};
    text-decoration: none;
  }

  &:focus {
    outline: 2px solid ${colors.purple};
    box-shadow: none;
  }

  /* flexbox */
  display: flex;
  align-items: center;
`;

const Thumbnail = styled.img`
  width: 80px;
  height: 45px;
  border-radius: 3%;
  margin-right: ${grid}px;
  flex-shrink: 0;
  flex-grow: 0;
`;

const Content = styled.div`
  /* flex child */
  flex-grow: 1;

  /*
    Needed to wrap text in ie11
    https://stackoverflow.com/questions/35111090/why-ie11-doesnt-wrap-the-text-in-flexbox
  */
  flex-basis: 100%;

  /* flex parent */
  display: flex;
  flex-direction: column;
`;

const TextLink = styled.span`
  &:hover {
   cursor: pointer;
  }
`;

const Footer = styled.small`
  margin: 0;
  margin-left: ${grid}px;
  text-align: right;
  font-size: 18px;
  flex-grow: 1;
`;

export default class EditableItem extends React.PureComponent {
    confirmDeletion = () => {
        const {item, removeItem} = this.props;
        confirmAlert({
            title:   'Remove ' + item.title + '?',
            message: 'Are you sure you want to remove from this set?',
            buttons: [
                {
                    label:   'Yes',
                    onClick: () => removeItem(item.id)
                },
                {
                    label: 'No'
                }
            ]
        })
    };

    editItem = () => {
        const {item}    = this.props;
        window.location = '/' + item.service + '/' + item.id;
    };

    render() {
        const {item, removeItem, style, onClick, isDragging, isGroupedOver, provided} = this.props;
        let itemTitle                                                                 = item.title;
        if (item.orderNumber) {
            itemTitle = 'E' + item.orderNumber + ': ' + itemTitle;
        }

        // Merge the custom style with the necessary draggableProps style
        provided.draggableProps.style = {...provided.draggableProps.style, ...style};

        if (provided) {
            return (
                <Container
                    onClick={onClick}
                    isDragging={isDragging}
                    isGroupedOver={isGroupedOver}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    {item.thumbnail &&
                    <Thumbnail src={item.thumbnail} alt={item.title} />
                    }
                    <Content>
                        {itemTitle}
                        <Footer>
                            <span>&nbsp;&nbsp;</span>
                            {item.service &&
                            <TextLink onClick={this.editItem}>
                                edit
                            </TextLink>
                            }
                            <span>&nbsp;&nbsp;</span>
                            {removeItem &&
                            <TextLink style={{color: 'red'}} onClick={this.confirmDeletion}>
                                X
                            </TextLink>
                            }
                        </Footer>
                    </Content>
                </Container>
            );
        }
        else {
            return (
                <Container
                    onClick={onClick}
                    isDragging={isDragging}
                    isGroupedOver={isGroupedOver}
                    style={style}
                >
                    {item.thumbnail &&
                    <Thumbnail src={item.thumbnail} alt={item.title} />
                    }
                    <Content>
                        {itemTitle}
                        <Footer>
                            <span>&nbsp;&nbsp;</span>
                            {item.service &&
                            <TextLink onClick={this.editItem}>
                                edit
                            </TextLink>
                            }
                            <span>&nbsp;&nbsp;</span>
                            {removeItem &&
                            <TextLink style={{color: 'red'}} onClick={this.confirmDeletion}>
                                X
                            </TextLink>
                            }
                        </Footer>
                    </Content>
                </Container>
            );
        }
    }
}