import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import { VolumeMenuButton } from 'video-react';

const propTypes = {
    player: PropTypes.object,
    className: PropTypes.string
};

export default class VolumeControl extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            mouseTime: {
                time: null,
                position: 0
            }
        };

        this.handleMouseUpFocus = this.handleMouseUp.bind(this);
    }

    handleMouseUp() {
        const { video } = this.props;
        video.focus();
    }

    render() {
        const { className } = this.props;
        return (
            <div
                onMouseUp={this.handleMouseUpFocus}
                className={classNames(
                    'video-react-volume-control video-react-control',
                    className
                )}
            >
                <VolumeMenuButton
                    {...this.props}
                    key="volume-menu-button"
                    vertical
                />
            </div>
        );
    }
}

VolumeControl.propTypes = propTypes;
VolumeControl.displayName = 'VolumeControl';
