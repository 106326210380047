const srt2vtt4web = {};

(() => {
    function transformCue(cue) {
        const re = /,/;
        return cue.replace(re, '.').replace(re, '.');
    }

    function parse(input) {
        let output = [];

        let buf_i = 0;

        let state = 'awaitingId';
        let subtitle = {
            id: null,
            cue: null,
            lines: []
        };
        let currentLine = '';

        while (true) {
            if (buf_i >= input.length) {
                // EOB
                if (state === 'awaitingTextLine') {
                    // Let EOB imply EOL
                    if (currentLine.length > 0) {
                        subtitle.lines.push(currentLine);
                    }

                    output.push(subtitle);
                }
                if (state === 'awaitingCue') {
                    return null;
                }

                return output;
            }

            let b = input[buf_i++];

            // Ignore CR - it should always be followed by a LF
            if (b === String.fromCharCode(0x0d)) {
                continue;
            }

            if (b === String.fromCharCode(0x0a)) {
                // EOL! What could it mean!

                if (state === 'awaitingId') {
                    if (currentLine.length === 0) {
                        continue; // skip extra blank lines between subtitles
                    }
                    subtitle.id = currentLine;
                    currentLine = '';
                    state = 'awaitingCue';
                    continue;
                }

                if (state === 'awaitingCue') {
                    subtitle.cue = transformCue(currentLine);
                    currentLine = '';
                    state = 'awaitingTextLine';
                    continue;
                }

                if (state === 'awaitingTextLine') {
                    if (currentLine.length === 0) {
                        output.push(subtitle);
                        subtitle = {
                            id: null,
                            cue: null,
                            lines: []
                        };
                        state = 'awaitingId';
                        continue;
                    }

                    subtitle.lines.push(currentLine);
                    currentLine = '';
                    continue;
                }
            } else {
                // Not EOL - build line
                currentLine += b;
            }
        }
    }

    function convert(input) {
        let outputLines = ['WEBVTT', ''];

        parse(input).forEach(data => {
            outputLines.push('');
            outputLines.push(data.id);
            outputLines.push(data.cue);
            outputLines = outputLines.concat(data.lines);
        });

        return outputLines.join('\n');
    }

    srt2vtt4web.parse = parse;
    srt2vtt4web.convert = convert;

    if (typeof module === 'object') module.exports = srt2vtt4web;
})();
