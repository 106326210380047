import PropTypes from 'prop-types';
import React, { Component } from 'react';

const propTypes = {
    actions: PropTypes.object,
    className: PropTypes.string,
    minutes: PropTypes.number
};

const defaultProps = {
    minutes: 10
};

export default mode => {
    class ForwardReplayControl extends Component {
        constructor(props, context) {
            super(props, context);
            this.handleClick = this.handleClick.bind(this);
        }

        handleClick() {
            const { actions, minutes } = this.props;
            // Depends mode to implement different actions
            if (mode === 'forward') {
                actions.forward(minutes * 60);
            } else {
                actions.replay(minutes * 60);
            }
        }

        render() {
            const { label, className } = this.props;
            const classNames = [
                'video-react-time-control',
                'video-react-control'
            ];
            const direction = mode === 'forward' ? '+' : '-';
            if (className) {
                classNames.push(className);
            }
            return (
                <div
                    ref={c => {
                        this.button = c;
                    }}
                    className={classNames.join(' ')}
                    onClick={this.handleClick}
                    style={{ cursor: 'pointer' }}
                >
                    <div className="video-react-current-time-display">{`${direction}${label}m`}</div>
                </div>
            );
        }
    }

    ForwardReplayControl.propTypes = propTypes;
    ForwardReplayControl.defaultProps = defaultProps;
    return ForwardReplayControl;
};
