export default class ServiceOptions {
    viewerOptions(serviceName) {
        const options = {
            singularName: '',
            uiSchema: null,
            requiresVideoPlayer: false,
            relationsToShow: [],
            linkOptions: {},
            querySequelize: {}
        };

        switch (serviceName) {
            case 'channels':
                options.singularName = 'channel';
                options.linkOptions.playlists = { requiresOrder: true };
                options.querySequelize = {
                    include: [
                        { association: 'media' },
                        { association: 'playlists' },
                        { association: 'products' }
                    ]
                };
                break;
            case 'users':
                options.singularName = 'user';
                break;
            case 'groups':
                options.singularName = 'group';
                options.relationsToShow = ['users'];
                options.querySequelize = {
                    include: [{ association: 'users' }]
                };
                break;
            case 'supergroups':
                options.singularName = 'supergroup';
                options.relationsToShow = ['users'];
                options.querySequelize = {
                    include: [{ association: 'users' }]
                };
                break;
            case 'media':
                options.singularName = 'medium';
                options.uiSchema = '../uiSchemas/media.uischema.json';
                options.requiresVideoPlayer = true;
                options.relationsToShow = [
                    {
                        relation: 'channels',
                        message:
                            'Warning: this media is currently live on {count} channels!'
                    },
                    {
                        relation: 'products',
                        message:
                            'Warning: this media is currently live on {channels.count} channels!'
                    }
                ];
                options.querySequelize = {
                    include: [
                        { association: 'channels' },
                        {
                            association: 'products',
                            include: [{ association: 'channels' }]
                        }
                    ]
                };
                break;

            case 'playlists':
                options.singularName = 'playlist';
                options.linkOptions.playlist = { requiresOrder: true };
                options.querySequelize = {
                    include: [
                        { association: 'channels' },
                        { association: 'media' },
                        { association: 'products' }
                    ]
                };
                break;
            case 'products':
                options.singularName = 'product';
                options.relationsToShow = [
                    {
                        relation: 'channels',
                        message:
                            'Warning: this media is currently live on {count} channels!'
                    }
                ];
                options.linkOptions.media = { filter: 'series' };
                options.querySequelize = {
                    include: [
                        { association: 'channels' },
                        { association: 'media' }
                    ]
                };
                break;
            default:
                options.default = true;
        }

        return options;
    }

    galleryOptions(serviceName) {
        const options = {
            defaultImageSource: '',
            querySequelize: {}
        };

        switch (serviceName) {
            case 'playlists':
                options.singularName = 'playlist';
                options.defaultImageSource =
                    'https://www.thestoreformusic.com/shared/images/playlist.svg';
                break;
            case 'users':
                options.singularName = 'user';
                options.defaultImageSource =
                    'https://cdn3.iconfinder.com/data/icons/linecons-interface/512/user_avatar-128.png';
                break;
            case 'groups':
                options.singularName = 'group';
                options.defaultImageSource =
                    'https://www.shareicon.net/download/2016/11/28/857762_list.ico';
                break;
            case 'media':
                options.singularName = 'medium';
                break;
            case 'products':
                options.singularName = 'product';
                break;
            case 'channels':
                options.singularName = 'channel';
                break;
            default:
                options.default = true;
        }

        return options;
    }

    constructor(serviceName, optionsType) {
        let options = {};
        switch (optionsType) {
            case 'viewer':
                options = this.viewerOptions(serviceName);
                break;
            case 'gallery':
                options = this.galleryOptions(serviceName);
                break;
            default:
                options.default = true;
        }
        return options;
    }
}
