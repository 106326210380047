/* MODIFIED FROM ORIGINAL https://raw.githubusercontent.com/video-react/video-react/9ccdd2bd248396fb1eaedcf5b5ce11ff5c3b8d1d/src/utils/index.js */
export function formatTime(seconds = 0, guide = seconds) {
    let ms = Math.round(seconds * 1000) % 1000;
    let s = Math.floor(seconds % 60);
    let m = Math.floor((seconds / 60) % 60);
    let h = Math.floor(seconds / 3600);
    const gm = Math.floor((guide / 60) % 60);
    const gh = Math.floor(guide / 3600);

    // handle invalid times
    if (isNaN(seconds) || seconds === Infinity) {
        // '-' is false for all relational operators (e.g. <, >=) so this setting
        // will add the minimum number of fields specified by the guide
        h = '-';
        m = '-';
        s = '-';
        ms = '-';
    }

    // Check if we need to show hours
    h = h > 0 || gh > 0 ? `${h}:` : '';

    // If hours are showing, we may need to add a leading zero.
    // Always show at least one digit of minutes.
    m = `${(h || gm >= 10) && m < 10 ? `0${m}` : m}:`;

    // Check if leading zero is need for seconds
    s = s < 10 ? `0${s}` : s;

    s += `.${('00' + ms).substr(-3)}`;

    return h + m + s;
}
