import React, { Component } from 'react';
import { Jumbotron } from 'react-bootstrap';
import '../styles/dad.css';
import ItemGalleryPage from './item-gallery-page';

export default class ItemGallery extends Component {
    render() {
        return (
            <Jumbotron>
                <ItemGalleryPage pushHistory {...this.props} />
            </Jumbotron>
        );
    }
}
