import React, { PureComponent } from 'react';
import { FormControl } from 'react-bootstrap';
import PropTypes from 'prop-types';

const imgWithClick     = {cursor: 'pointer'};
const selectedImgStyle = {
    transform:  'translateZ(0px) scale3d(0.9, 0.9, 1)',
    transition: 'transform .135s cubic-bezier(0.0,0.0,0.2,1),opacity linear .15s'
};
const cont             = {
    overflow: 'hidden',
    float:    'left',
    position: 'relative'
}

const Checkmark = ({selected}) => (
    <div style={selected ? {left: '4px', top: '4px', position: 'absolute', zIndex: '1'} : {display: 'none'}}>
        <svg style={{fill: 'white', position: 'absolute'}} width="24px" height="24px">
            <circle cx="12.5" cy="12.2" r="8.292"></circle>
        </svg>
        <svg style={{fill: '#06befa', position: 'absolute'}} width="24px" height="24px">
            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"></path>
        </svg>
    </div>
);

export default class Photo extends PureComponent {
    handleClick = (event) => {
        const {onClick, index, photo} = this.props;
        onClick(event, {photo, index});
    }

    onChange = (event, context) => {
//        if (context.type === 'number') {
//            event.target.value = parseInt(event.target.value.replace(/\D/,''), 10);
//        }
        if (context.onChange) {
            context.onChange(event);
        }
    }

    render() {
        const {photo, onClick, margin, index} = this.props;

        const photoFields = photo.fields;
        delete photo.fields;

        const imgStyle    = {display: 'block'};
        const figStyle    = {display: 'table', float: 'left', margin: margin};
        const figCapStyle = {
            display:   'table-caption', captionSide: 'bottom', padding: '0 5px 5px', margin: '0 0 25px 0',
            maxHeight: '25px'
        };

        const sx                   = (100 - ((30 / photo.width) * 100)) / 100;
        const sy                   = (100 - ((30 / photo.height) * 100)) / 100;
        selectedImgStyle.transform = `translateZ(0px) scale3d(${sx}, ${sy}, 1)`;

        return (
            <div style={{margin, width: photo.width, ...cont}}>
                <Checkmark selected={!!photo.selected} />

                <figure style={onClick ? {...figStyle, ...imgWithClick} : figStyle}
                        onClick={onClick ? this.handleClick : null}>
                    <img style={photo.selected ? {...imgStyle, ...selectedImgStyle} : {...imgStyle}} {...photo} // eslint-disable-line jsx-a11y/alt-text
                    />
                    <figcaption style={figCapStyle}>{photo.title}</figcaption>
                </figure>

                {photoFields && photoFields.map((field, i) => (
                    <FormControl onChange={(event) => this.onChange(event, field)} key={i} type={field.type || 'text'} value={field.value || ''}
                                 id={field.name + '_' + index} name={field.name} placeholder={field.name} disabled={!photo.selected} autoComplete={(field.autoComplete === false) ? 'off': 'on'} />
                ))}
            </div>
        );
    }
}

export const photoPropType = PropTypes.shape({
    src:            PropTypes.string.isRequired,
    width:          PropTypes.number.isRequired,
    height:         PropTypes.number.isRequired,
    alt:            PropTypes.string,
    title:          PropTypes.string,
    srcSet:         PropTypes.array,
    sizes:          PropTypes.array,
    fields:         PropTypes.array
});

Photo.propTypes = {
    index:   PropTypes.number,
    onClick: PropTypes.func,
    photo:   photoPropType
};