import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import * as Dom from './dom';
import { SeekBar } from 'video-react';

const propTypes = {
    player: PropTypes.object,
    className: PropTypes.string
};

export default class ProgressControl extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            mouseTime: {
                time: null,
                position: 0
            }
        };

        this.handleMouseUpFocus = this.handleMouseUp.bind(this);
        this.handleMouseMoveThrottle = this.handleMouseMove.bind(this);
    }

    handleMouseUp() {
        const { video } = this.props;
        video.focus();
    }

    handleMouseMove(event) {
        if (!event.pageX) {
            return;
        }
        const {
            player: { duration }
        } = this.props;
        const node = this.seekBar.slider.slider;
        const newTime = Dom.getPointerPosition(node, event).x * duration;
        const position = event.pageX - Dom.findElPosition(node).left;

        this.setState({
            mouseTime: {
                time: newTime,
                position
            }
        });
    }

    render() {
        const { className } = this.props;
        return (
            <div
                onMouseMove={this.handleMouseMoveThrottle}
                onMouseUp={this.handleMouseUpFocus}
                className={classNames(
                    'video-react-progress-control video-react-control',
                    className
                )}
            >
                <SeekBar
                    mouseTime={this.state.mouseTime}
                    ref={c => {
                        this.seekBar = c;
                    }}
                    {...this.props}
                />
            </div>
        );
    }
}

ProgressControl.propTypes = propTypes;
ProgressControl.displayName = 'ProgressControl';
