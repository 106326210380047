import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Grid, Button } from 'react-bootstrap';
import client from '../Feathers';

export default class Browse extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showGroupMenu: true
        };
    }

    componentDidMount() {
        this.showGrouping();
        client.on('authenticated', this.showGrouping);
    }

    showGrouping = () =>
        client
            .service('supergroups')
            .find()
            .catch(({ message }) => {
                if (message) {
                    this.setState({ showGroupMenu: false });
                }
            });

    render() {
        const { showGroupMenu } = this.state;

        return (
            <Grid>
                <Link to="/media">
                    <Button
                        className="big-button backlit col-md-4 col-md-offset-4"
                        bsSize="large"
                    >
                        Media
                    </Button>
                </Link>
                <Link to="/products">
                    <Button
                        className="big-button backlit col-md-4 col-md-offset-4"
                        bsSize="large"
                    >
                        Products
                    </Button>
                </Link>
                <Link to="/playlists">
                    <Button
                        className="big-button backlit col-md-4 col-md-offset-4"
                        bsSize="large"
                    >
                        Playlists
                    </Button>
                </Link>
                <Link to="/channels">
                    <Button
                        className="big-button backlit col-md-4 col-md-offset-4"
                        bsSize="large"
                    >
                        Channels
                    </Button>
                </Link>
                <Link to="/users">
                    <Button
                        className="big-button backlit col-md-4 col-md-offset-4"
                        bsSize="large"
                    >
                        Users
                    </Button>
                </Link>
                {showGroupMenu && (
                    <div>
                        <Link to="/groups">
                            <Button
                                className="big-button backlit col-md-4 col-md-offset-4"
                                bsSize="large"
                            >
                                Groups
                            </Button>
                        </Link>
                    </div>
                )}
                <Link to="/batch">
                    <Button
                        className="big-button backlit col-md-4 col-md-offset-4"
                        bsSize="large"
                    >
                        Batch
                    </Button>
                </Link>
            </Grid>
        );
    }
}
