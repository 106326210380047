import React, { Component } from 'react';
import client from '../Feathers';
import {
    Grid,
    Button,
    Form,
    FormGroup,
    ControlLabel,
    FormControl,
    Alert,
    ProgressBar
} from 'react-bootstrap';

const batchService = client.service('batch');

export default class Batch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            url: '',
            loading: false,
            progress: 0,
            result: null,
            skus: [],
            errors: null
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        this.setState({ url: event.target.value });
    }

    handleSubmit(event) {
        event.preventDefault();

        this.setState({
            loading: true,
            result: null,
            errors: null,
            progress: 0
        });
        batchService
            .create({ url: this.state.url })
            .then(() => {
                batchService.on('progress', progress => {
                    if (progress > this.state.progress + 4) {
                        this.setState({ progress });
                    }
                });
                batchService.once('end', result => {
                    this.setState({
                        url: '',
                        loading: false,
                        result: result.saved,
                        skus: result.skus,
                        errors: result.errors
                    });
                });
            })
            .catch(error => {
                this.setState({ url: '', loading: false, progress: 0 });
                alert(error);
            });
    }

    render() {
        let errorMsgs = [];

        if (this.state.errors) {
            const allFailedSkus = [];
            errorMsgs = this.state.errors.map(error => {
                if (!allFailedSkus.includes(error.sku)) {
                    allFailedSkus.push(error.sku);
                }
                return <li>{`${error.sku}: ${error.msg}`}</li>;
            });
            errorMsgs.push(
                <li>{`All failed SKUs: ${allFailedSkus.join(', ')}`}</li>
            );
        }
        return (
            <Grid>
                {this.state.result ? (
                    <Alert bsStyle="success">
                        Found {this.state.result.length} files and added them to
                        the queue! SKUs: {this.state.skus.join(', ')}
                    </Alert>
                ) : null}
                {errorMsgs.length ? (
                    <Alert bsStyle="warning">
                        Found {errorMsgs.length - 1} issues:
                        <ul>{errorMsgs}</ul>
                    </Alert>
                ) : null}
                {this.state.loading ? (
                    <>
                        <span className="loading">Loading...</span>
                        <ProgressBar animated now={this.state.progress} />
                    </>
                ) : (
                    <Form onSubmit={this.handleSubmit}>
                        <FormGroup controlId="batch">
                            <ControlLabel>Dropbox Folder URL:</ControlLabel>
                            <FormControl
                                type="text"
                                placeholder="URL"
                                autoFocus
                                value={this.state.value}
                                onChange={this.handleChange}
                            />
                        </FormGroup>
                        <Button variant="primary" type="submit">
                            Submit
                        </Button>
                    </Form>
                )}
            </Grid>
        );
    }
}
