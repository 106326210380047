import React from 'react';
import styled from 'styled-components';
import EditableItem from './editable-item';
import { Draggable, Droppable } from 'react-beautiful-dnd'
import { grid, colors } from './constants';

const Wrapper = styled.div`
  background-color: ${colors.blue.light};
  display: flex;
  flex-direction: column;
  opacity: inherit;
  padding: ${grid}px;
  border: ${grid}px;
  padding-bottom: 0;
  transition: background-color 0.1s ease, opacity 0.1s ease;
  user-select: none;
  width: 50%;
`;

const Title = styled.h4`
  text-align: center;
  margin-bottom: ${grid}px;
`;

const ScrollContainer = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  min-height: 280px;
  max-height: 280px;
`;

const DropZone = styled.div`
  /* stop the list collapsing when empty */
  min-height: 280px;
  /*
    not relying on the items for a margin-bottom
    as it will collapse when the list is empty
  */
  margin-bottom: ${grid}px;
`;

/* stylelint-disable block-no-empty */
const Container = styled.div``;

/* stylelint-enable */

class InnerItemList extends React.Component {
    render() {
        const {removeItem, items, style, dndEnabled} = this.props;

        return items.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index} isDragDisabled={!dndEnabled}>
                    {(dragProvided, dragSnapshot) => (
                        <EditableItem
                            key={item.id}
                            item={item}
                            removeItem={removeItem}
                            style={style}
                            onClick={item.filter}
                            isDragging={dragSnapshot.isDragging}
                            isGroupedOver={Boolean(dragSnapshot.combineTargetFor)}
                            provided={dragProvided}
                        />
                    )}
                </Draggable>
            )
        );
    }
}

class InnerList extends React.Component {
    render() {
        const {
                  items,
                  removeItem,
                  style,
                  dndEnabled,
                  dropProvided,
              }     = this.props;
        const title = this.props.title ? <Title>{this.props.title}</Title> : null;

        return (
            <Container>
                {title}
                <DropZone ref={dropProvided.innerRef}>
                    <InnerItemList removeItem={removeItem} items={items} style={style} dndEnabled={dndEnabled} />
                    {dropProvided.placeholder}
                </DropZone>
            </Container>
        )
    }
}

export default class EditableItemList extends React.Component {
    render() {
        const {
                  scrollContainerStyle,
                  style,
                  itemStyle,
                  items,
                  title,
                  removeItem,
                  dndEnabled,
                  listId,
                  listType,
                  ignoreContainerClipping,
                  ...otherProps
              } = this.props;

        return (
            <Droppable
                droppableId={listId}
                type={listType}
                ignoreContainerClipping={ignoreContainerClipping}
                isDropDisabled={!dndEnabled}
            >
                {(dropProvided, dropSnapshot) => (
                    <Wrapper style={style}
                             isDraggingOver={dropSnapshot.isDraggingOver}
                             {...dropProvided.droppableProps}>
                        <ScrollContainer style={scrollContainerStyle}>
                            <InnerList
                                style={itemStyle}
                                items={items}
                                title={title}
                                removeItem={removeItem}
                                dropProvided={dropProvided}
                                dndEnabled={dndEnabled}
                                {...otherProps}
                            />
                        </ScrollContainer>
                    </Wrapper>
                )}
            </Droppable>
        )
    }
}