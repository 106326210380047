import React from 'react';
import AddButton from 'react-jsonschema-form/lib/components/AddButton';
import IconButton from 'react-jsonschema-form/lib/components/IconButton';

function ArrayFieldTitle({ TitleField, idSchema, title, required }) {
    if (!title) {
        return null;
    }
    const id = `${idSchema.$id}__title`;
    return <TitleField id={id} title={title} required={required} />;
}

function ArrayFieldDescription({ DescriptionField, idSchema, description }) {
    if (!description) {
        return null;
    }
    const id = `${idSchema.$id}__description`;
    return <DescriptionField id={id} description={description} />;
}

function DefaultArrayItem(props) {
    const btnStyle = {
        flex: 1,
        paddingLeft: 6,
        paddingRight: 6,
        fontWeight: 'bold'
    };

    return (
        <div key={props.key} className={props.className}>
            <div className={props.hasToolbar ? 'col-xs-9' : 'col-xs-12'}>
                {props.children}
            </div>

            {props.hasToolbar && (
                <div className="col-xs-3 array-item-toolbox">
                    <div
                        className="btn-group"
                        style={{
                            display: 'flex',
                            justifyContent: 'space-around'
                        }}
                    >
                        {(props.hasMoveUp || props.hasMoveDown) && (
                            <IconButton
                                icon="arrow-up"
                                className="array-item-move-up"
                                tabIndex="-1"
                                style={btnStyle}
                                disabled={
                                    props.disabled ||
                                    props.readonly ||
                                    !props.hasMoveUp
                                }
                                onClick={props.onReorderClick(
                                    props.index,
                                    props.index - 1
                                )}
                            />
                        )}

                        {(props.hasMoveUp || props.hasMoveDown) && (
                            <IconButton
                                icon="arrow-down"
                                className="array-item-move-down"
                                tabIndex="-1"
                                style={btnStyle}
                                disabled={
                                    props.disabled ||
                                    props.readonly ||
                                    !props.hasMoveDown
                                }
                                onClick={props.onReorderClick(
                                    props.index,
                                    props.index + 1
                                )}
                            />
                        )}

                        {props.hasRemove && (
                            <IconButton
                                type="danger"
                                icon="remove"
                                className="array-item-remove"
                                tabIndex="-1"
                                style={btnStyle}
                                disabled={props.disabled || props.readonly}
                                onClick={props.onDropIndexClick(props.index)}
                            />
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}

function UniqueSelects(props) {
    const chosenValues = props.formData.filter(value => value);

    return (
        <fieldset className={props.className} id={props.idSchema.$id}>
            <ArrayFieldTitle
                key={`array-field-title-${props.idSchema.$id}`}
                TitleField={props.TitleField}
                idSchema={props.idSchema}
                title={props.uiSchema['ui:title'] || props.title}
                required={props.required}
            />

            {(props.uiSchema['ui:description'] || props.schema.description) && (
                <ArrayFieldDescription
                    key={`array-field-description-${props.idSchema.$id}`}
                    DescriptionField={props.DescriptionField}
                    idSchema={props.idSchema}
                    description={
                        props.uiSchema['ui:description'] ||
                        props.schema.description
                    }
                />
            )}

            <div
                className="row array-item-list"
                key={`array-item-list-${props.idSchema.$id}`}
            >
                {props.items &&
                    props.items.map(p => {
                        p.children.props.uiSchema[
                            'ui:enumDisabled'
                        ] = chosenValues;
                        return DefaultArrayItem(p);
                    })}
            </div>

            {props.canAdd && (
                <AddButton
                    className="array-item-add"
                    onClick={props.onAddClick}
                    disabled={props.disabled || props.readonly}
                />
            )}
        </fieldset>
    );
}

export default UniqueSelects;
