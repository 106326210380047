import React from 'react';
import { Grid, Row, Col } from 'react-bootstrap';
import moment from 'moment';
import '../style/footer.css';

export default function subFooter() {
    return (
        <footer className="footer">
            <Grid>
                <Row className="subFooter">
                    <Col xs={12}>
                        <span style={{ float: 'right' }}>
                            &copy; media-manager {moment().format('YYYY')}
                        </span>
                        <p>&nbsp;</p>
                    </Col>
                </Row>
            </Grid>
        </footer>
    );
}
