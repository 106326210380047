import React, { Component } from 'react';
import { Form, Input } from 'formsy-react-components';
import { Grid, Button } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
import queryString from 'query-string';
import client from '../../Feathers';
import './login.css';
import { schema } from '../../config';

class Reset extends Component {
    constructor(props) {
        super(props);
        const { email, token, type } = queryString.parse(
            window.location.search
        );
        this.state = {
            email,
            token,
            type,
            confirmPassword: '',
            password: '',
            canSubmit: false
        };
    }

    handleInputChange = (target, value) => {
        this.setState({
            [target]: value
        });
    };

    enableButton = () => {
        this.setState({
            canSubmit: true
        });
    };

    disableButton = () => {
        this.setState({
            canSubmit: false
        });
    };

    reset = async () => {
        const {
            data: { token, email }
        } = this.props;

        const { password } = this.state;
        const value = { token, password };

        try {
            this.sendToken('resetPwdLong', value).then(() => {
                return this.login();
            });
        } catch (err) {
            if (err.message.includes('No record found for')) {
                alert('Sorry you can not do this!');
            } else {
                confirmAlert({
                    title: 'Your notification email expired',
                    message: 'Do you want us to renew it for you now?',
                    buttons: [
                        {
                            label: 'Yes',
                            onClick: async () => {
                                try {
                                    await client
                                        .service('authManagement')
                                        .create({
                                            action: 'sendResetPwd',
                                            value: { email }
                                        });
                                } catch ({ message }) {
                                    alert(message);
                                }
                            }
                        },
                        {
                            label: 'No'
                        }
                    ]
                });
            }
        }
    };

    login = () => {
        const { email, password } = this.state;

        return client
            .authenticate({
                strategy: 'local',
                email,
                password
            })
            .catch(({ data }) => {
                alert(data.message);
                this.setState({ canRenewPassword: true });
            });
    };

    sendToken = (action, value) =>
        client.service('authManagement').create({ action, value }, {});

    render() {
        const { confirmPassword, password } = this.state;
        return (
            <div id="renew-page">
                <Grid>
                    <Form
                        id="signin"
                        onValid={this.enableButton}
                        onInvalid={this.disableButton}
                        onValidSubmit={this.reset}
                    >
                        <h3 className="text-center signin-header">
                            Set Password
                        </h3>
                        <Input
                            type="password"
                            name="password"
                            label="Password"
                            value={password}
                            onChange={this.handleInputChange}
                            validations={{
                                validatePassword: (values, value) =>
                                    schema.validate(value)
                                        ? true
                                        : '8 characters or more with at least: (1) digit, (1) uppercase, and (1) lowercase letter'
                            }}
                            required
                            rowClassName="addMargin"
                            className="form-control glowing-border backlit"
                            labelClassName={[
                                { 'col-sm-3': false },
                                'col-md-4 col-md-offset-4 text-left'
                            ]}
                            elementWrapperClassName={[
                                { 'col-sm-9': false },
                                'col-md-4 col-md-offset-4'
                            ]}
                        />

                        <Input
                            type="password"
                            name="confirmPassword"
                            label="Confirm Password"
                            value={confirmPassword}
                            onChange={this.handleInputChange}
                            validations="equalsField:password"
                            validationErrors={{
                                equalsField: 'Passwords must match.'
                            }}
                            required
                            rowClassName="addMargin"
                            className="form-control glowing-border backlit"
                            labelClassName={[
                                { 'col-sm-3': false },
                                'col-md-4 col-md-offset-4 text-left'
                            ]}
                            elementWrapperClassName={[
                                { 'col-sm-9': false },
                                'col-md-4 col-md-offset-4'
                            ]}
                        />

                        <Button
                            disabled={!this.state.canSubmit}
                            className="col-md-4 col-md-offset-4"
                            type="submit"
                            bsSize="large"
                        >
                            Set Password
                        </Button>
                    </Form>
                </Grid>
            </div>
        );
    }
}

export default Reset;
