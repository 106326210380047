function BIF(bifData) {
    // based on BIF file specification found at:
    // https://developer.roku.com/docs/developer-program/media-playback/trick-mode.md#bif-file-specification

    this.imageCount = 0;
    this.timestampMultiplier = 0;
    this.timestamps = [];
    this.offsets = [];
    this.error = null;

    this.getImageAtIndex = index => {
        if (index >= this.imageCount) {
            return null;
        }

        const start = this.offsets[index];
        const end = this.offsets[index + 1];
        const jpgData = bif8Bit.slice(start, end);
        const blob = new Blob([jpgData], { type: 'image/jpeg' });

        return blob;
    };

    const bif8Bit = new Uint8Array(bifData);

    if (
        bif8Bit[0] !== 0x89 ||
        bif8Bit[1] !== 0x42 ||
        bif8Bit[2] !== 0x49 ||
        bif8Bit[3] !== 0x46 ||
        bif8Bit[4] !== 0x0d ||
        bif8Bit[5] !== 0x0a ||
        bif8Bit[6] !== 0x1a ||
        bif8Bit[7] !== 0x0a
    ) {
        this.error = 'magic number not found. not a bif file ?';
        return;
    }

    let bif32Bit = new Uint32Array(bifData, 0, 20); // cut it to avoid 'not multiple of' error

    this.imageCount = bif32Bit[12 / 4];
    this.timestampMultiplier = bif32Bit[16 / 4];

    bif32Bit = new Uint32Array(bifData, 0, 64 + this.imageCount * 2); // reassign now that we know image count

    for (let i = 0; i < this.imageCount + 1; i++) {
        const index = 64 / 4 + i * 2;

        const offset = bif32Bit[index + 1];
        this.offsets.push(offset);

        const rawTimestamp = bif32Bit[index];

        if (rawTimestamp !== 0xffffffff) {
            const timestamp = rawTimestamp * this.timestampMultiplier;
            this.timestamps.push(timestamp);
        } else {
            break; // just in case 💁
        }
    }
}

if (typeof module === 'object') module.exports = BIF;
