import React, { Component } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { Form, Input } from 'formsy-react-components';
import { Grid, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import client from '../../Feathers';
import './login.css';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: props.email || '',
            password: '',
            canSubmit: false
        };
    }

    handleInputChange = (target, value) => {
        this.setState({
            [target]: value
        });
    };

    enableButton = () => {
        this.setState({
            canSubmit: true
        });
    };

    disableButton = () => {
        this.setState({
            canSubmit: false
        });
    };

    login = () => {
        return client
            .authenticate({
                strategy: 'local',
                email: this.state.username,
                password: this.state.password
            })
            .catch(error => {
                const { data } = error;
                if (data && data.message) {
                    alert(data.message);
                } else {
                    alert(error);
                }
                this.setState({ canRenewPassword: true });
            });
    };

    resetRequest = async () => {
        const { username } = this.state;
        const email = username;
        confirmAlert({
            title: 'Forgot Password?',
            message:
                'Click "Confirm" to receive an email to reset your password.',
            buttons: [
                {
                    label: 'Confirm',
                    onClick: async () => {
                        try {
                            await client.service('authManagement').create({
                                action: 'sendResetPwd',
                                value: { email }
                            });
                        } catch ({ message }) {
                            // setLink('#')
                            alert(message);
                        }
                    }
                },
                {
                    label: 'Cancel'
                }
            ]
        });
    };

    render() {
        return (
            <div id="login-page">
                <Grid>
                    <Form
                        id="signin"
                        onValid={this.enableButton}
                        onInvalid={this.disableButton}
                        onValidSubmit={this.login}
                    >
                        <h3 className="text-center signin-header">Sign In</h3>
                        <Input
                            name="username"
                            label="Username"
                            value={this.state.username}
                            onChange={this.handleInputChange}
                            required
                            rowClassName="addMargin"
                            className="form-control glowing-border backlit"
                            labelClassName={[
                                { 'col-sm-3': false },
                                'col-md-4 col-md-offset-4 text-left'
                            ]}
                            elementWrapperClassName={[
                                { 'col-sm-9': false },
                                'col-md-4 col-md-offset-4'
                            ]}
                        />
                        <Input
                            type="password"
                            name="password"
                            label="Password"
                            value={this.state.password}
                            onChange={this.handleInputChange}
                            required
                            rowClassName="addMargin"
                            className="form-control glowing-border backlit"
                            labelClassName={[
                                { 'col-sm-3': false },
                                'col-md-4 col-md-offset-4 text-left'
                            ]}
                            elementWrapperClassName={[
                                { 'col-sm-9': false },
                                'col-md-4 col-md-offset-4'
                            ]}
                        />

                        <Button
                            disabled={!this.state.canSubmit}
                            className="col-md-4 col-md-offset-4"
                            type="submit"
                            bsSize="large"
                        >
                            Login
                        </Button>
                        {this.state.canRenewPassword && (
                            <div className="col-md-4 col-md-offset-4">
                                <Link to="/#" onClick={this.resetRequest}>
                                    Forgot Password?
                                </Link>
                            </div>
                        )}
                    </Form>
                </Grid>
            </div>
        );
    }
}

export default Login;
