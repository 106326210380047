import feathers from '@feathersjs/client';
import socketio from '@feathersjs/socketio-client';
import io from 'socket.io-client';
import auth from '@feathersjs/authentication-client';
import { config } from './config';

const socket = io(config.backendUrl, {
    transports: ['websocket'],
    forceNew: true
});

const client = feathers()
    .configure(socketio(socket, { timeout: 15000 }))
    .configure(auth({ storage: window.localStorage }));

client.service('channels').timeout = 600000;
client.service('products').timeout = 20000;
client.service('media').timeout = 6000000;

export default client;
