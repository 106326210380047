import React, { Component } from 'react';
import styled from 'styled-components';
import EditableItemList from './editable-item-list';
import { colors } from './constants';

const seasonListStyle = {
    maxWidth: '150px',
    backgroundColor: colors.white
};
const seasonItemStyle = {
    backgroundColor: colors.white,
    border: 'none',
    cursor: 'pointer'
};

const Root = styled.div`
    box-sizing: border-box;
    padding: 16px;
    min-height: 10vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
`;

export class IconButton extends Component {
    render() {
        const {
            type = 'default',
            icon,
            text,
            className,
            ...otherProps
        } = this.props;
        return (
            <button
                type="button"
                className={`btn btn-${type} ${className}`}
                {...otherProps}
            >
                <i className={`glyphicon glyphicon-${icon}`} />
                {text && ' ' + text}
            </button>
        );
    }
}

export class SingleList extends Component {
    render() {
        const { items, onRemoveItem, ...otherProps } = this.props;
        return (
            <Root>
                <EditableItemList
                    listId="items-list"
                    items={items}
                    internalScroll={true}
                    removeItem={onRemoveItem}
                    {...otherProps}
                />
            </Root>
        );
    }
}

export class MediaLists extends Component {
    render() {
        const { items, onRemoveItem, seasons, ...otherProps } = this.props;
        return (
            <Root>
                <EditableItemList
                    listId="seasons-list"
                    style={seasonListStyle}
                    itemStyle={seasonItemStyle}
                    items={seasons}
                    internalScroll={true}
                    {...otherProps}
                />
                <EditableItemList
                    listId="items-list"
                    items={items}
                    internalScroll={true}
                    removeItem={onRemoveItem}
                    {...otherProps}
                />
            </Root>
        );
    }
}
