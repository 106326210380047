import React, { Component } from 'react';
import {
    Row,
    Col,
    FormGroup,
    FormControl,
    InputGroup,
    Button,
    Pagination
} from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import Gallery from 'react-photo-gallery';
import Measure from 'react-measure';
import Photo from '../photo-with-caption';
import client from '../Feathers';
import '../styles/dad.css';
import 'video-react/dist/video-react.css';
import ServiceOptions from '../ServiceOptions';
import helpers from '../helpers';

export default class ItemGalleryPage extends Component {
    constructor(props) {
        super(props);

        let serviceName = '';
        if (
            props.match &&
            props.match.params &&
            props.match.params.serviceName
        ) {
            serviceName = props.match.params.serviceName;
        } else if (props.name) {
            serviceName = props.name;
        }

        const serviceOptions = new ServiceOptions(serviceName, 'gallery');
        const handleThumbnailClick = props.handleThumbnailClick || null;
        const forcedColumns = props.forcedColumns || -1;
        const forcedWidth = props.forcedWidth || -1;
        const query = {};

        if (props.filter) {
            query.$filter = props.filter;
        }
        if (Object.keys(serviceOptions.querySequelize).length) {
            query.sequelize = serviceOptions.querySequelize;
        }

        this.state = {
            serviceName,
            serviceOptions,
            query,
            handleThumbnailClick,
            forcedColumns,
            forcedWidth,
            schema: null,
            results: [],
            searchTerm: '',
            pages: 0,
            activePage: 0,
            limit: 0,
            width: -1,
            redirectToEdit: false,
            chosenItem: -1
        };
    }

    handleInputChange = event => {
        const target = event.target;
        const value = target.value;
        const name = target.id;

        this.setState({
            [name]: value
        });
    };

    componentDidMount() {
        const schemaService = client.service('schemas');
        const serviceName = this.state.serviceName;

        schemaService
            .get(serviceName)
            .then(schema => {
                this.setState({ schema });
            })
            .catch(alert);

        // Turning this off as it's way too chatty when batching updates:
        //        const service = client.service(serviceName);
        //        service.on('created', () => this.search());
        //        service.on('updated', () => this.search());
        //        service.on('patched', () => this.search());
        //        service.on('removed', () => this.search());

        this.handleLocationChange();
        if (this.props.history) {
            this.props.history.listen(this.handleLocationChange);
        }
    }

    handleLocationChange = () => {
        const url = new URL(window.location.href);
        const searchTerm = url.searchParams.get('search') || '';
        const activePage = parseInt(url.searchParams.get('page')) || 1;

        if (
            activePage !== this.state.activePage ||
            searchTerm !== this.state.searchTerm
        ) {
            this.setState({ activePage, searchTerm }, this.search);
        }
    };

    pushQuery = () => {
        if (this.props.history && this.props.pushHistory) {
            const { searchTerm, activePage } = this.state;
            let queryString = '?';
            const props = [];

            if (searchTerm && searchTerm.length) {
                props.push(`search=${searchTerm}`);
            }

            if (activePage && activePage > 1) {
                props.push(`page=${activePage}`);
            }

            if (props.length) {
                queryString = `?${props.join('&')}`;
            }

            const url = new URL(window.location.href);

            if (queryString !== (url.search || '?')) {
                this.props.history.push(queryString);
            }
        }
    };

    search = async () => {
        const { serviceName, searchTerm, query, activePage } = this.state;
        let limit = this.state.limit;
        const service = client.service(serviceName);
        const params = { query };
        let results;

        params.query.$search = searchTerm;

        if (activePage > 1) {
            if (!limit) {
                params.query.$skip = 0;
                results = await service.find(params);
                limit = results.limit;
            }

            params.query.$skip = (activePage - 1) * limit;
        } else {
            params.query.$skip = 0;
        }

        try {
            results = await service.find(params);
        } catch (error) {
            return alert(error);
        }

        this.setState(
            {
                results: results.data,
                pages: results.total / results.limit + 1,
                limit: results.limit
            },
            this.pushQuery
        );
    };

    handleAddNew = () =>
        this.setState({ redirectToEdit: true, chosenItem: 'new' });

    handleThumbnailClick = (event, imgObj) => {
        const { handleThumbnailClick, results } = this.state;
        if (handleThumbnailClick) {
            handleThumbnailClick(results[imgObj.index]);
        } else {
            this.setState({
                redirectToEdit: true,
                chosenItem: this.state.results[imgObj.index].id
            });
        }
    };

    handlePageSelect = eventKey => {
        const activePage = eventKey;

        this.setState({ activePage }, this.search);
    };

    freshSearch = () => {
        this.handlePageSelect(1);
    };

    onError = errors => {
        console.error('errors:', errors);
    };

    render() {
        const {
            serviceName,
            serviceOptions,
            width,
            forcedColumns,
            forcedWidth,
            redirectToEdit,
            chosenItem
        } = this.state;
        const { searchTerm, results, pages, activePage } = this.state;
        const { singularName, defaultImageSource } = serviceOptions;

        if (redirectToEdit) {
            return (
                <Redirect
                    push
                    to={{
                        pathname: `/${serviceName}/${chosenItem}`,
                        state: { from: this.props.location }
                    }}
                />
            );
        }

        const content = (
            <Measure
                bounds
                onResize={contentRect =>
                    this.setState({ width: contentRect.bounds.width })
                }
            >
                {({ measureRef }) => {
                    let columns = 1;
                    if (forcedColumns > 0) {
                        columns = forcedColumns;
                    } else {
                        if (width < 1) {
                            return <div ref={measureRef}>&nbsp;</div>;
                        }
                        if (width >= 480) {
                            columns = 2;
                        }
                        if (width >= 1024) {
                            columns = 4;
                        }
                        if (width >= 1824) {
                            columns = 5;
                        }
                    }
                    return (
                        <div ref={measureRef}>
                            <Gallery
                                columns={columns}
                                onClick={this.handleThumbnailClick}
                                photos={results.map((item, index) => {
                                    const imgSrc = helpers.getThumbUrl(
                                        item.metadata.assets,
                                        defaultImageSource
                                    );
                                    let imgText =
                                        item.metadata.title || 'Unknown';
                                    if (
                                        item.metadata.series &&
                                        item.metadata.series.title
                                    ) {
                                        imgText += ` - ${item.metadata.series.title}`;
                                    }
                                    if (item.metadata.friendlyId) {
                                        imgText = `${item.metadata.friendlyId} ${imgText}`;
                                    }

                                    return {
                                        key: item.id,
                                        src: imgSrc,
                                        height: 270,
                                        width: 480,
                                        alt: imgText,
                                        title: imgText
                                    };
                                })}
                                ImageComponent={Photo}
                            />
                        </div>
                    );
                }}
            </Measure>
        );

        const divStyle = {};
        if (forcedWidth !== -1) {
            divStyle.width = forcedWidth;
        }

        return (
            <div style={divStyle}>
                <h1 className="text-center">{serviceName}</h1>
                <br />
                <Row>
                    <Col sm={4} smOffset={4}>
                        <FormGroup>
                            <InputGroup>
                                <FormControl
                                    type="text"
                                    inputRef={input =>
                                        (this.searchInput = input)
                                    }
                                    id="searchTerm"
                                    placeholder={
                                        searchTerm.length
                                            ? ''
                                            : `Search ${serviceName}...`
                                    }
                                    value={searchTerm}
                                    onChange={this.handleInputChange}
                                    onKeyPress={e => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault();
                                            this.freshSearch();
                                        }
                                    }}
                                />
                                <InputGroup.Button>
                                    <Button onClick={this.freshSearch}>
                                        Search
                                    </Button>
                                </InputGroup.Button>
                            </InputGroup>
                        </FormGroup>
                    </Col>
                    <Col sm={4}>
                        <InputGroup.Button>
                            <Button onClick={this.handleAddNew}>
                                Add New {`${singularName}`}
                            </Button>
                        </InputGroup.Button>
                    </Col>
                </Row>
                <div className="results" style={{ marginBottom: 80 }}>
                    {content}
                    <Pagination
                        bsSize="medium"
                        items={pages}
                        activePage={activePage}
                        onSelect={this.handlePageSelect}
                    />
                </div>
            </div>
        );
    }
}
