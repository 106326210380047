import React, { Component } from 'react';
import { Row, Col, Button, Jumbotron } from 'react-bootstrap';
import Form from 'react-jsonschema-form';
import { Redirect } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import { generate } from 'generate-password';
import TimeFormat from 'hh-mm-ss';
import { Parser } from 'm3u8-parser';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import moment from 'moment';
import {
    Player,
    Shortcut,
    ControlBar,
    PlayToggle,
    TimeDivider
} from 'video-react';
import Validator from 'validator';
import * as vttAdjust from 'vtt-adjust';
import client from '../Feathers';
import ItemLinker from '../Linkers/item-linker';
import PlaylistLinker from '../Linkers/playlist-linker';
import AdBreak from '../uiSchemas/custom-adbreak';
import ServiceOptions from '../ServiceOptions';
import UISchemaLoader from '../uiSchemas/ui-schema';
import Spinner from '../images/spinner.gif';
import helpers from '../helpers';
import PauseAtAdBreaks from '../video-react-customizations/PauseAtAdBreaks';
import ClosedCaptions from '../video-react-customizations/ClosedCaptioning';

import DurationDisplay from '../video-react-customizations/DurationDisplay.js';
import CurrentTimeDisplay from '../video-react-customizations/CurrentTimeDisplay.js';
import ForwardMinutesControl from '../video-react-customizations/ForwardMinutesControl';
import ProgressControl from '../video-react-customizations/ProgressControl';
import VolumeControl from '../video-react-customizations/VolumeControl';
import PreviousAdBreakControl from '../video-react-customizations/PreviousAdBreakControl';
import NextAdBreakControl from '../video-react-customizations/NextAdBreakControl';

import '../styles/dad.css';
import 'video-react/dist/video-react.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { schema } from '../config';

const additionalMetaSchemas = require('ajv/lib/refs/json-schema-draft-06');
const Peaks = require('peaks.js');
const srt2vtt4web = require('../srt2vtt4web.js');
const BIF = require('../bif.js');
const originUri = 'f79eceef0e55.hatchfarmstudios.com';

const FORMSTATE = {
    BLANK: 0,
    INITIALIZED: 1,
    UPDATED: 2
};

function convertCaptionsIfNeeded(dataUrl) {
    const split = dataUrl.split(',');
    const search = /name=([^;]*)/.exec(split[0]);

    if (search && search[1]) {
        const filename = search[1];

        if (filename.split('.').pop() === 'srt') {
            let content = split[1];

            if (split[0].indexOf('base64') > -1) {
                content = atob(content);
            }

            let vttData = srt2vtt4web.convert(content);

            if (vttData) {
                vttData = vttData.replace(/<[^>]*>/g, ''); // remove HTML tags

                return `data:;base64,${btoa(vttData)}`;
            }
            alert('Error converting this subtitle file');
        }
    }

    return null;
}

const base64encode = str =>
    btoa(
        encodeURIComponent(str).replace(/%([a-f0-9]{2})/gi, (m, $1) =>
            String.fromCharCode(parseInt($1, 16))
        )
    );
//const base64decode = (str) => decodeURIComponent(atob(str).replace(/[\x80-\uffff]/g, (m) => `%${m.charCodeAt(0).toString(16).padStart(2, '0')}`));

const CharacterCountTextInput = props => {
    const { value } = props;
    const { TextWidget } = props.registry.widgets;
    const maxLength = props.schema.maxLength;

    return (
        <div>
            <TextWidget {...props} />
            <div>
                {(value || '').length} / {maxLength}
            </div>
        </div>
    );
};

class ThumbnailBar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            thumbnails: []
        };

        this.item = props.item;
        this.player = props.player;

        this.bif = null;
        this.timeToBifIndexMap = null;
        this.container = null;

        this.player.subscribeToStateChange(this.handlePlayerStateChange);

        this.loadBif();
    }

    loadBif = () => {
        if (
            this.player &&
            this.item &&
            this.item.metadata.trickPlays &&
            this.item.metadata.trickPlays.length
        ) {
            const trickPlays = this.item.metadata.trickPlays.sort((a, b) => {
                const aQ = parseInt(a.quality);
                const bQ = parseInt(b.quality);

                return bQ - aQ;
            });

            const bifKeyed = {};
            trickPlays.forEach(
                trickPlay => (bifKeyed[trickPlay.quality] = trickPlay)
            );
            const bifHighest =
                bifKeyed['1080p'] || bifKeyed['720p'] || bifKeyed['240p'];
            const bifUrl = bifHighest.uri;

            const xhr = new XMLHttpRequest();
            xhr.responseType = 'arraybuffer';
            xhr.addEventListener('readystatechange', () => {
                if (xhr.readyState === 4) {
                    this.bif = new BIF(xhr.response);

                    if (this.bif.error) {
                        console.log(this.bif.error);
                    } else {
                        this.player.subscribeToStateChange(
                            this.handlePlayerStateChange.bind(this)
                        );
                    }
                }
            });

            xhr.open('GET', bifUrl);
            xhr.send();
        }
    };

    handlePlayerStateChange = (state, prevState) => {
        const isVideo = state.duration !== 0;
        const hasBiff = !!this.bif;
        // Make sure the play head has moved so we don't just keep creating the same thumbnails over and over
        const isTimeDifferent =
            state.currentTime !== prevState.currentTime ||
            state.seekingTime !== prevState.seekingTime;

        if (isTimeDifferent && isVideo && hasBiff) {
            if (!this.timeToBifIndexMap) {
                // The way the BIF format works there is no guarantee that images will be evenly spaced in time.
                // So here we construct a mapping for every second of the video to an image index. We only do this once.
                // Alternatively, we could search the timestamp index each time we want an image at a specific time.
                // But this would be slower... So a lookup table it is:

                const times = this.bif.timestamps.slice(1).map(time => {
                    return Math.floor(time / 1000);
                });
                times.push(Math.ceil(state.duration));

                this.timeToBifIndexMap = [];
                let i = 0;

                times.forEach((time, index) => {
                    for (i; i < time; i++) {
                        this.timeToBifIndexMap.push(index);
                    }
                });
            }

            const time = Math.floor(state.seekingTime || state.currentTime);
            const index = this.timeToBifIndexMap[time];
            const thumbnails = [];

            let count = 5;
            if (this.container) {
                count = Math.floor(this.container.offsetWidth / 104);
            }

            let start = index - Math.floor(count / 2);
            if (start < 0) {
                start = 0;
            }

            let end = start + count;
            const max = Math.ceil(state.duration);
            if (end > max) {
                end = max;
                start = max - count;
            }

            for (let i = start; i < end; i++) {
                const blob = this.bif.getImageAtIndex(i);

                if (!blob) {
                    continue;
                }

                const url = URL.createObjectURL(blob);

                thumbnails.push(
                    <div
                        className="thumbnail-box"
                        key={`bif-${i}`}
                        onMouseOver={() => {
                            if (this.onHover) {
                                this.onHover(url);
                            }
                        }}
                        onMouseOut={() => {
                            if (this.onHover) {
                                this.onHover(null);
                            }
                        }}
                    >
                        <img
                            className="thumbnail-image"
                            alt="thumbnail"
                            src={url}
                            onClick={() => {
                                const seconds = this.bif.timestamps[i] / 1000;
                                this.player.seek(seconds);
                            }}
                        />
                    </div>
                );
            }

            this.setState({ thumbnails });
        }
    };

    render = () => {
        return (
            <div
                className="thumbnail-bar"
                ref={container => {
                    this.container = container;
                }}
            >
                {this.state.thumbnails}
            </div>
        );
    };
}

export default class ItemViewer extends Component {
    constructor(props) {
        super(props);
        // the name of the current service
        const serviceName = props.match.params.serviceName;
        // the id of the item edited if none then its 'new'
        const itemId = props.match.params.id;
        // service options as defined in ServiceOptions.js ... mainly
        const serviceOptions = new ServiceOptions(serviceName, 'viewer');
        // a feathersjs query template, has the login access token, then defined with include options
        const query = { query: { sequelize: serviceOptions.querySequelize } };
        //
        // Fields defined: ItemLinker, PlaylistLinker and AddBreak
        const fields = {
            linker: ItemLinker,
            playlistLinker: PlaylistLinker,
            adbreak: AdBreak
        };

        // included as state and initialised
        this.state = {
            serviceName,
            itemId,
            serviceOptions,
            query,
            uiSchema: {},
            fields,
            messageAcknowledged: false,
            playlistItems: [],
            item: null,
            schema: null,
            showSaveProgress: false,
            redirectToGallery: false,
            showGroupMenu: false,
            stickyVideo: true,
            columnWidth: 0,
            rowHeight: 0,
            thumbnailBar: null,
            formUpdateState: FORMSTATE.BLANK,
            formattedAdBreaks: '',
            formattedAdBreaksSorted: '',
            itemHighestQualityHLSLink: '',
            adBreakCopyStyle: 'btn-primary',
            adBreakSortedCopyStyle: 'btn-primary',
            nextAdBreak: 0,
            previousAdBreak: 0,
            shouldPauseAdBreak: false,
            windowWidth: window.innerWidth,
            convertedCaption: null,
            itemCaptionAdjuster: null,
            playerCurrentTime: 0,
            showAdjuster: false
        };
        this.player = null;
        this.handleResize = this.handleResize.bind(this);
        this.handleAdjuster = this.handleAdjuster.bind(this);
    }

    handleAdjuster = () => {
        this.setState({ showAdjuster: !this.state.showAdjuster });
    };

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions = () => {
        this.setState({
            columnWidth: Math.floor(0.5 * window.innerWidth),
            rowHeight: Math.floor(0.8 * window.innerHeight)
        });
    };

    handleResize() {
        this.setState({ windowWidth: window.innerWidth });
    }

    componentWillMount() {
        window.addEventListener('resize', this.handleResize);
    }

    videoPlayerShortcuts = [
        {
            // Set Right Arrow to jump ahead one second
            keyCode: 39,
            handle: (player, actions) => {
                if (!player.hasStarted) {
                    return;
                }

                const operation = {
                    action: 'forward-1',
                    source: 'shortcut'
                };
                actions.forward(1, operation);
            }
        },
        {
            // Set Left Arrow to jump back one second
            keyCode: 37, // Right arrow
            handle: (player, actions) => {
                if (!player.hasStarted) {
                    return;
                }

                const operation = {
                    action: 'back-1',
                    source: 'shortcut'
                };
                actions.replay(1, operation);
            }
        },
        {
            // Set SHIFT+Right Arrow to jump ahead one tenth of a second
            keyCode: 39,
            shift: true,
            handle: (player, actions) => {
                if (!player.hasStarted) {
                    return;
                }

                const operation = {
                    action: 'forward-tenth',
                    source: 'shortcut'
                };
                actions.forward(1 / 10, operation);
            }
        },
        {
            // Set SHIFT+Left Arrow to jump back one tenth of a second
            keyCode: 37, // Right arrow
            shift: true,
            handle: (player, actions) => {
                if (!player.hasStarted) {
                    return;
                }

                const operation = {
                    action: 'back-tenth',
                    source: 'shortcut'
                };
                actions.replay(1 / 10, operation);
            }
        },
        {
            // Set SHIFT+Ctrl+Right Arrow to jump ahead one hundredth of a second
            keyCode: 39,
            shift: true,
            ctrl: true,
            handle: (player, actions) => {
                if (!player.hasStarted) {
                    return;
                }

                const operation = {
                    action: 'forward-hundredth',
                    source: 'shortcut'
                };
                actions.forward(1 / 100, operation);
            }
        },
        {
            // Set SHIFT+Ctrl+Left Arrow to jump back one hundredth of a second
            keyCode: 37, // Right arrow
            shift: true,
            ctrl: true,
            handle: (player, actions) => {
                if (!player.hasStarted) {
                    return;
                }

                const operation = {
                    action: 'back-hundredth',
                    source: 'shortcut'
                };
                actions.replay(1 / 100, operation);
            }
        },
        {
            // Set SHIFT+Ctrl+Opt+Right Arrow to jump ahead one thousandth of a second
            keyCode: 39,
            shift: true,
            ctrl: true,
            alt: true,
            handle: (player, actions) => {
                if (!player.hasStarted) {
                    return;
                }

                const operation = {
                    action: 'forward-thousandth',
                    source: 'shortcut'
                };
                actions.forward(1 / 1000, operation);
            }
        },
        {
            // Set SHIFT+Ctrl+Opt+Right Arrow to jump back one thousandth of a second
            keyCode: 37, // Right arrow
            shift: true,
            ctrl: true,
            alt: true,
            handle: (player, actions) => {
                if (!player.hasStarted) {
                    return;
                }

                const operation = {
                    action: 'back-thousandth',
                    source: 'shortcut'
                };
                actions.replay(1 / 1000, operation);
            }
        },
        {
            keyCode: 70,
            handle: () => {}
        }
    ];

    showGrouping = async () => {
        try {
            const { user } = await client.reAuthenticate();
            const { supergroupId } = await client.service('users').get(user.id);
            this.setState({ showGroupMenu: !!supergroupId });
        } catch ({ message }) {
            alert(message);
        }
    };

    // adding relations to the schema ... thus adding extra form fields for related data
    addRelationsToFormSchema = schema => {
        const { serviceName, serviceOptions, uiSchema } = this.state;

        // if the include options.querySequelize.include in Service.Options.js is not empty
        if (
            serviceOptions.querySequelize &&
            serviceOptions.querySequelize.include &&
            serviceOptions.querySequelize.include.length
        ) {
            // for each of the relations object specified in serviceOptions
            serviceOptions.querySequelize.include.forEach(relationship => {
                // exact name of associated model
                // `playlists` gets a custom view since it needs to add
                // both media and products together in one list

                const model = relationship.association;

                if (
                    serviceName === 'playlists' &&
                    (model === 'products' || model === 'media')
                ) {
                    if (!schema.properties.playlist) {
                        schema.properties.playlist = {
                            type: 'array',
                            items: {
                                type: 'object',
                                properties: {
                                    itemId: {
                                        type: 'string'
                                    },
                                    itemModel: {
                                        type: 'string'
                                    }
                                }
                            }
                        };
                        uiSchema.playlist = {
                            'ui:field': 'playlistLinker'
                        };
                    }
                } else if (
                    // Hide products from Media to prevent bad assignments
                    !(serviceName === 'media' && model === 'products') &&
                    // Hide all relations if a new media or product
                    !(
                        (serviceName === 'media' ||
                            serviceName === 'products') &&
                        this.state.itemId === 'new'
                    )
                ) {
                    schema.properties[model] = {
                        type: 'array',
                        items: {
                            type: 'object',
                            properties: {
                                [`${model}Id`]: {
                                    type: 'string'
                                }
                            }
                        }
                    };
                    uiSchema[model] = {
                        'ui:field': 'linker'
                    };
                    //                    } else {
                    //                    }
                }
            });
        }
        return schema;
    };

    // thus adding related Data to FormData
    addRelationsToFormData = item => {
        const { serviceOptions, serviceName } = this.state;
        if (
            serviceOptions.querySequelize &&
            serviceOptions.querySequelize.include &&
            serviceOptions.querySequelize.include.length
        ) {
            serviceOptions.querySequelize.include.forEach(relationship => {
                item.metadata[relationship.association] =
                    item[relationship.association];
            });
        }

        if (serviceName === 'playlists') {
            item.metadata.playlist = item.media.concat(item.products);
            item.metadata.playlist.sort((a, b) => {
                const aOrder = a.hasOwnProperty('mediaPlaylists')
                    ? a.mediaPlaylists.orderNumber
                    : a.productsPlaylists.orderNumber;
                const bOrder = b.hasOwnProperty('mediaPlaylists')
                    ? b.mediaPlaylists.orderNumber
                    : b.productsPlaylists.orderNumber;
                return aOrder - bOrder;
            });
        }

        return item;
    };

    removeRelationsFromFormData = item => {
        const { serviceOptions, serviceName } = this.state;
        if (
            serviceOptions.querySequelize &&
            serviceOptions.querySequelize.include &&
            serviceOptions.querySequelize.include.length
        ) {
            serviceOptions.querySequelize.include.forEach(relationship => {
                delete item.metadata[relationship.association];
            });
        }
        if (serviceName === 'playlists') {
            delete item.metadata.playlist;
        }
        return item;
    };

    setHighestQualityHLSLink = item => {
        if (
            item.metadata &&
            item.metadata.files &&
            item.metadata.files.length
        ) {
            const hlsFile = item.metadata.files.find(
                file => file.fileType === 'HLSv3'
            );
            if (hlsFile) {
                const videoUrl = `${hlsFile.uri}?d=0`;
                const masterUrl = new URL(hlsFile.uri);
                // Force direct origin video loads
                masterUrl.host = originUri;

                const xhr = new XMLHttpRequest();
                xhr.addEventListener('readystatechange', () => {
                    if (xhr.readyState === 4) {
                        const parser = new Parser();
                        parser.push(xhr.response);
                        parser.end();

                        const parsedMaster = parser.manifest;
                        if (
                            parsedMaster.playlists &&
                            parsedMaster.playlists.length
                        ) {
                            let highestQualityHLS = parsedMaster.playlists[0];
                            parsedMaster.playlists.forEach(playlist => {
                                const highestQualityBandwidth = parseInt(
                                    highestQualityHLS.attributes[
                                        'AVERAGE-BANDWIDTH'
                                    ],
                                    10
                                );
                                const thisPlaylistBandwidth = parseInt(
                                    playlist.attributes['AVERAGE-BANDWIDTH'],
                                    10
                                );
                                if (
                                    thisPlaylistBandwidth >
                                    highestQualityBandwidth
                                ) {
                                    highestQualityHLS = playlist;
                                }
                            });

                            // Handle relative URIs for the playlist
                            let highestQualityUrl = '';
                            if (!highestQualityHLS.uri.startsWith('http')) {
                                const masterPath = masterUrl.pathname.split(
                                    '/'
                                );
                                masterPath.pop(); // remove the filename
                                highestQualityUrl = new URL(
                                    `${masterPath.join('/')}/${highestQualityHLS.uri}`,
                                    `https://${originUri}`
                                );
                            } else {
                                highestQualityUrl = new URL(highestQualityHLS.uri);
                                highestQualityUrl.host = originUri;
                            }

                            this.setState({
                                itemHighestQualityHLSLink: highestQualityUrl.toString()
                            });
                        }
                    }
                });

                xhr.open('GET', videoUrl);
                xhr.send();
            }
        }
    };

    setItemCaptionFile = item => {
        if (
            item.metadata.subtitles &&
            item.metadata.subtitles.length &&
            item.metadata.subtitles[0].uri
        ) {
            let loadFile = item.metadata.subtitles[0].uri;

            if (loadFile.endsWith('.srt')) {
                loadFile = loadFile.replace('.srt', '.vtt');
            }

            const xhr = new XMLHttpRequest();
            xhr.responseType = 'arraybuffer';
            xhr.addEventListener('readystatechange', () => {
                if (xhr.readyState === 4 && xhr.response) {
                    const vttCaption = new Buffer.from(xhr.response).toString();
                    const adjuster = vttAdjust(vttCaption);

                    this.setState({
                        itemCaptionAdjuster: adjuster
                    });
                }
            });

            xhr.open('GET', loadFile);
            xhr.send();
        }
    };

    onNewCaptions = newCaptions => {
        const { item } = this.state;
        const convertedCaption = `data:;base64,${base64encode(newCaptions)}`;

        if (!item.metadata.subtitles || !item.metadata.subtitles[0]) {
            item.metadata.subtitles = [
                {
                    type: 'closedCaptions',
                    language: 'en-US'
                }
            ];
        }
        delete item.metadata.subtitles[0].uri;
        item.metadata.subtitles[0].vttText = newCaptions;

        this.setState({
            item,
            convertedCaption,
            formUpdateState: FORMSTATE.UPDATED
        });
    };

    handlePauseAdBreak = isEnabled => {
        this.setState({
            shouldPauseAdBreak: isEnabled
        });
    };

    async componentDidMount() {
        const { serviceName, itemId, query } = this.state;
        const service = client.service(serviceName);
        const schemaService = client.service('schemas');
        const uiSchema = await UISchemaLoader(serviceName);
        const fetches = [schemaService.get(serviceName)];

        if (itemId !== 'new') {
            fetches.push(service.get(itemId, query), this.showGrouping());
        }

        Promise.all(fetches)
            .then(([schema, item]) => {
                const stateUpdate = {};

                schema = this.addRelationsToFormSchema(schema);

                if (serviceName === 'users' && itemId !== 'new') {
                    delete schema.properties.email;
                    delete schema.properties.password;
                    delete schema.properties.confirmPassword;
                }

                stateUpdate.schema = schema;
                stateUpdate.uiSchema = uiSchema;

                if (item) {
                    item = this.addRelationsToFormData(item);
                    if (item.metadata && item.metadata.adBreaks) {
                        item.metadata.adBreaksSorted = item.metadata.adBreaks
                            .slice()
                            .sort((a, b) => a - b);
                    }
                    stateUpdate.item = item;
                    this.setHighestQualityHLSLink(item);
                    this.setItemCaptionFile(item);
                }

                this.setState(stateUpdate, () => {
                    this.createFormattedAdBreaks();
                    this.setState({ formUpdateState: FORMSTATE.INITIALIZED });
                });
            })
            .catch(alert);

        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    linkedItemsUpdate = ({ serviceName, items }) => {
        const { item } = this.state;
        const linkFromService = this.state.serviceName;

        items.forEach((item, i) => (item.metadata.currentOrderNumber = i));

        if (item) {
            item[serviceName] = items;
        }

        if (linkFromService === 'playlists') {
            if (serviceName === 'products' || serviceName === 'media') {
                item[serviceName] = [];
                items.forEach((linkedItem, i) => {
                    if (linkedItem[`${serviceName}Playlists`]) {
                        linkedItem[`${serviceName}Playlists`].orderNumber = i;
                        item[serviceName].push(linkedItem);
                    }
                });
            } else if (serviceName === 'playlist') {
                item.media = [];
                item.products = [];
                items.forEach(linkedItem => {
                    if (
                        linkedItem.metadata.hasOwnProperty('currentOrderNumber')
                    ) {
                        if (linkedItem.mediaPlaylists) {
                            item.media.push(linkedItem);
                        } else if (linkedItem.productsPlaylists) {
                            item.products.push(linkedItem);
                        }
                    }
                });
            }
        }

        this.setState({ item, formUpdateState: FORMSTATE.UPDATED });
    };

    onChange = form => {
        const { item, formUpdateState } = this.state;
        const updatedMetadata = form.formData;
        const stateUpdate = {};
        let stateUpdateCallback = () => {
            this.createFormattedAdBreaks();
        };

        if (item) {
            item.metadata = form.formData;

            const itemGenres = item.metadata.genres || [];
            const formGenres = form.formData.genres || [];
            if (
                !(
                    itemGenres.every(e => formGenres.includes(e)) &&
                    formGenres.every(e => itemGenres.includes(e))
                )
            ) {
                item.metadata.genres = [];
                stateUpdateCallback = () => {
                    this.createFormattedAdBreaks();

                    const { item } = this.state;
                    item.metadata.genres = formGenres;

                    // Do not change formUpdateState here as this is just refreshing the genres schema
                    this.setState({ item });
                };
            }

            if (updatedMetadata.subtitles) {
                const subWithFile = updatedMetadata.subtitles.find(
                    subtitle => subtitle.file
                );
                if (subWithFile) {
                    stateUpdate.convertedCaption = convertCaptionsIfNeeded(
                        subWithFile.file
                    );
                }
            }

            if (updatedMetadata.adBreaks) {
                item.metadata.adBreaksSorted = updatedMetadata.adBreaks
                    .slice()
                    .sort((a, b) => a - b);
            }

            stateUpdate.item = item;
        }

        if (formUpdateState === FORMSTATE.INITIALIZED) {
            stateUpdate.formUpdateState = FORMSTATE.UPDATED;
        }

        if (Object.keys(stateUpdate).length) {
            this.setState(stateUpdate, stateUpdateCallback);
        }
    };

    saveItem = form => {
        const { serviceName, query, itemId } = this.state;
        let { item } = this.state;
        const service = client.service(serviceName);

        if (itemId === 'new') {
            item = {};
        }

        if (item.metadata && item.metadata.adBreaksSorted) {
            delete item.metadata.adBreaksSorted;
        }

        this.showSaveIndicator(() => {
            form.formData.brands = this.state.assignedBrands;
            item.metadata = form.formData;
            item = this.removeRelationsFromFormData(item);

            if (item && item.id) {
                if (serviceName !== 'users') {
                    service
                        .update(item.id, item, query)
                        .then(item => {
                            // the above returns the item *before* it was modified, so let's get the new one:
                            service
                                .get(item.id, query)
                                .then(item => {
                                    item = this.addRelationsToFormData(item);
                                    if (
                                        item.metadata &&
                                        item.metadata.adBreaks
                                    ) {
                                        item.metadata.adBreaksSorted = item.metadata.adBreaks
                                            .slice()
                                            .sort((a, b) => a - b);
                                    }
                                    this.setState({
                                        item,
                                        formUpdateState: FORMSTATE.INITIALIZED
                                    });
                                    this.hideSaveIndicator();
                                })
                                .catch(err => {
                                    this.hideSaveIndicator();

                                    alert(err);
                                });
                        })
                        .catch(err => {
                            this.hideSaveIndicator();

                            alert(err);
                        });
                } else {
                    const { metadata, id } = item;
                    service
                        .patch(id, { metadata })
                        .then(item => {
                            this.props.history.push(`/${serviceName}`);
                            this.setState({
                                item: form.formData,
                                formUpdateState: FORMSTATE.INITIALIZED
                            });
                            this.hideSaveIndicator();
                        })
                        .catch(err => {
                            this.hideSaveIndicator();
                            alert(err);
                        });
                }
            } else {
                // creating new data

                if (serviceName !== 'users') {
                    service
                        .create(item)
                        .then(item => {
                            this.props.history.push(
                                `/${serviceName}/${item.id}`
                            );
                            this.setState({
                                item,
                                formUpdateState: FORMSTATE.INITIALIZED
                            });
                            this.hideSaveIndicator();
                        })
                        .catch(err => {
                            this.hideSaveIndicator();
                            alert(err);
                        });
                } else {
                    const {
                        email,
                        password,
                        firstname,
                        lastname
                    } = form.formData;
                    const data = {
                        email,
                        password,
                        metadata: { email, password, firstname, lastname }
                    };
                    service
                        .create(data)
                        .then(() => {
                            this.props.history.push(`/${serviceName}`);
                            this.setState({
                                item,
                                formUpdateState: FORMSTATE.INITIALIZED
                            });
                            this.hideSaveIndicator();
                        })
                        .catch(err => {
                            this.hideSaveIndicator();
                        });
                }
            }
        });
    };

    submitForm = () => {
        this.submitButton.click();
    };

    deleteItem = () => {
        const serviceName = this.state.serviceName;
        const service = client.service(serviceName);

        return service
            .remove(this.state.item.id)
            .then(() => {
                this.setState({ redirectToGallery: true });
            })
            .catch(alert);
    };

    confirmDelete = () => {
        const { item, serviceOptions } = this.state;
        const { singularName } = serviceOptions;

        confirmAlert({
            title: `Delete ${item.metadata.title}?`,
            message: `Are you sure you want to remove this ${singularName}?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => this.deleteItem()
                },
                {
                    label: 'No'
                }
            ]
        });
    };

    showSaveIndicator = cb => {
        window.scrollTo(0, 0);
        this.setState({ showSaveProgress: true }, cb);
    };

    hideSaveIndicator = () => {
        this.setState({ showSaveProgress: false });
    };

    generateFeedSheet = () => {
        const { itemId, item } = this.state;
        const service = client.service(`channels/${itemId}/generateSheet`);
        service
            .create({})
            .then(sheet => {
                const blob = new Blob([sheet], {
                    type: 'application/vnd.ms-excel'
                });
                const a = document.createElement('a');
                a.style = 'display: none';
                document.body.appendChild(a);
                // Create a DOMString representing the blob and point the link element towards it
                const url = window.URL.createObjectURL(blob);
                a.href = url;
                a.download = `${item.metadata.title}.xlsx`;
                // Programatically click the link to trigger the download
                a.click();
                // Release the references to the file by revoking the Object URL and anchor
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
            })
            .catch(error => {
                alert(`Error generating sheet\n\n${error}`);
            });
    };

    generateFeed = () => {
        const { itemId } = this.state;
        const service = client.service(`channels/${itemId}/generate`);
        service
            .create({})
            .then(info => {
                if (Array.isArray(info)) {
                    const newInfo = info.filter(
                        item =>
                            item.errors &&
                            !item.errors.includes('licenseExpired')
                    );
                    newInfo.forEach(item => {
                        console.log(
                            `${item.id},${item.friendlyId},"${
                                item.title
                            }",${item.errors.join()},`
                        );
                    });

                    const justInfo = info.filter(item => item.info);
                    justInfo.forEach(item => {
                        console.log(
                            `${item.id},${item.friendlyId},"${item.title}",`,
                            item.info
                        );
                    });

                    const expired = info.filter(
                        item =>
                            item.errors &&
                            item.errors.includes('licenseExpired')
                    );
                    expired.forEach(item => {
                        console.log(
                            `${item.id},${item.friendlyId},"${
                                item.title
                            }",${item.errors.join()},`
                        );
                    });
                }

                alert('Feed created successfully');
            })
            .catch(error => {
                alert(`Error generating feed\n\n${error}`);
            });
    };

    publishFeed = () => {
        const { itemId } = this.state;
        const service = client.service(`channels/${itemId}/publish`);
        service
            .create({})
            .then(info => {
                alert('Feed successfully queued for publishing');
            })
            .catch(error => {
                alert(`Error publishing feed\n\n${error}`);
            });
    };

    validateUserData = (formData, errors) => {
        const { item } = this.state;

        if (!(item && item.id)) {
            if (formData.email && !Validator.isEmail(formData.email)) {
                errors.email.addError('email must be valid');
            }

            if (formData.password && !schema.validate(formData.password)) {
                errors.password.addError(
                    '8 characters or more with at least; a digit, uppercase and lowercase letter'
                );
            }

            if (formData.password !== formData.confirmPassword) {
                errors.confirmPassword.addError("passwords don't match");
            }
        }
        return errors;
    };

    messageAcknowledged = () => {
        this.setState({ messageAcknowledged: true });
    };

    createFormattedAdBreaks = () => {
        const { item } = this.state;

        if (item) {
            const { adBreaks, adBreaksSorted } = item.metadata;
            const stateUpdate = {};
            if (adBreaks && adBreaks.length) {
                stateUpdate.formattedAdBreaks = adBreaks.map(adBreak => {
                    if (adBreak) {
                        return TimeFormat.fromS(adBreak, 'hh:mm:ss.sss');
                    }
                    return null;
                });
            }
            if (adBreaksSorted && adBreaksSorted.length) {
                stateUpdate.formattedAdBreaksSorted = adBreaksSorted.map(
                    adBreak => {
                        if (adBreak) {
                            return TimeFormat.fromS(adBreak, 'hh:mm:ss.sss');
                        }
                        return null;
                    }
                );
            }
            if (Object.keys(stateUpdate).length) {
                this.setState(stateUpdate);
            }
        }
    };

    addAdBreak = () => {
        const { item, formUpdateState } = this.state;
        const stateUpdate = {};

        if (
            this.player &&
            this.player.video &&
            this.player.video.video &&
            this.player.video.video.currentTime
        ) {
            const time =
                Math.round(this.player.video.video.currentTime * 1000) / 1000;
            if (!item.metadata.adBreaks) {
                item.metadata.adBreaks = [];
            }
            item.metadata.adBreaks.push(time);
            item.metadata.adBreaksSorted = item.metadata.adBreaks
                .slice()
                .sort((a, b) => a - b);
            stateUpdate.item = item;

            if (formUpdateState === FORMSTATE.INITIALIZED) {
                stateUpdate.formUpdateState = FORMSTATE.UPDATED;
            }

            this.setState(stateUpdate, this.createFormattedAdBreaks);
        }
    };

    addThumbnail = () => {
        const { item } = this.state;

        if (!this.player) {
            return;
        }

        const video = this.player.video.video;
        const width = video.videoWidth;
        const height = video.videoHeight;

        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;

        const context = canvas.getContext('2d');
        context.drawImage(video, 0, 0);

        const dataUrl = canvas.toDataURL('image/jpeg');

        const asset = {
            title: 'video-frame',
            width,
            height,
            type: 'still',
            file: dataUrl
        };

        if (!item.metadata.assets) {
            item.metadata.assets = [];
        }
        item.metadata.assets = item.metadata.assets.filter(
            asset => asset.title !== 'video-frame'
        );
        item.metadata.assets.push(asset);
        this.setState({ item, formUpdateState: FORMSTATE.UPDATED });
    };

    handlePlayerStateChange = (state, prevState) => {
        const {
            item,
            shouldPauseAdBreak,
            nextAdBreak: currentNextAdBreak
        } = this.state;
        const isVideo = state.duration !== 0;
        const isTimeDifferent =
            state.currentTime !== prevState.currentTime ||
            state.seekingTime !== prevState.seekingTime;

        this.setState({ playerCurrentTime: state.currentTime });

        if (isTimeDifferent && isVideo) {
            if (
                shouldPauseAdBreak &&
                currentNextAdBreak > 0 &&
                state.currentTime >= currentNextAdBreak
            ) {
                this.player.pause();
                this.player.seek(currentNextAdBreak);
            }

            const adBreaks = item.metadata.adBreaksSorted;
            if (adBreaks && adBreaks.length) {
                let nextAdBreak = adBreaks.find(
                    adBreak => adBreak > state.currentTime
                );
                if (!nextAdBreak) {
                    nextAdBreak = 0;
                }

                let previousAdBreak = 0;
                adBreaks.some(adBreak => {
                    if (adBreak < state.currentTime) {
                        previousAdBreak = adBreak;
                        return false;
                    }
                    return true;
                });

                this.setState({ previousAdBreak, nextAdBreak });
            }
        }
    };

    setupPlayer = player => {
        this.player = player;

        if (this.player) {
            player.video.video.setAttribute('crossOrigin', 'anonymous');

            const thumbnailBar = (
                <ThumbnailBar
                    item={this.state.item}
                    player={this.player}
                    onClick={alert}
                />
            );

            this.player.subscribeToStateChange(this.handlePlayerStateChange);

            this.setState({ thumbnailBar });
        }
    };

    setupPeaks = container => {
        const { item, windowWidth } = this.state;
        const points = [];
        let height = 60;
        let zoomLevels = [1800];
        if (
            !this.player ||
            !item ||
            !item.metadata ||
            !item.metadata.peaksUrl
        ) {
            return;
        }

        if (windowWidth >= 1600) {
            height = 300;
            zoomLevels = [1800];
        }

        const { metadata } = item;
        if (metadata.adBreaks && metadata.adBreaks.length) {
            metadata.adBreaks.forEach((adBreak, i) =>
                points.push({
                    time: adBreak,
                    editable: true,
                    id: `adBreak${i}`,
                    labelText: `${TimeFormat.fromS(adBreak, 'hh:mm:ss.sss')}`
                })
            );
        }

        const peaksUrl = item.metadata.peaksUrl;
        const dataUri = {};
        if (peaksUrl.endsWith('.json')) {
            dataUri.json = peaksUrl;
        } else {
            dataUri.arraybuffer = peaksUrl;
        }

        const options = {
            containers: {
                zoomview: container,
                overview: document.getElementById('overview-container')
            },
            dataUri,
            mediaElement: this.player.video.video,
            height,
            overviewHighlightOffset: 1,
            overviewHighlightColor: 'gray',
            axisGridlineColor: 'rgba(255, 255, 255, 0)',
            axisLabelColor: 'rgba(255, 255, 255, 0)',
            pointMarkerColor: '#0022FF',
            zoomLevels,
            points
        };

        Peaks.init(options, (error, peaks) => {
            if (!error && peaks) {
                const view = peaks.views.getView('zoomview');
                view.setZoom({ seconds: 5.0 });
                view.setAmplitudeScale(5);

                // TODO: make these editable for fine placement of markers (adBreaks)
                view.enableMarkerEditing(false);
            }
        });
    };

    lastUpdated = () => {
        const { item } = this.state;
        let updatedText;

        if (item) {
            updatedText = moment(item.updatedAt).fromNow();
        } else {
            updatedText = 'N/A';
        }

        return updatedText;
    };

    flashSuccessButton = buttonId => {
        this.setState({ [`${buttonId}Style`]: 'btn-success' });
        setTimeout(
            () => this.setState({ [`${buttonId}Style`]: 'btn-primary' }),
            250
        );
    };

    render() {
        const {
            serviceOptions,
            itemId,
            serviceName,
            schema,
            uiSchema,
            fields,
            messageAcknowledged,
            showSaveProgress,
            redirectToGallery,
            convertedCaption,
            itemCaptionAdjuster,
            formUpdateState,
            columnWidth,
            rowHeight,
            adBreakCopyStyle,
            adBreakSortedCopyStyle,
            formattedAdBreaks,
            formattedAdBreaksSorted,
            itemHighestQualityHLSLink,
            previousAdBreak,
            nextAdBreak,
            playerCurrentTime,
            showAdjuster
        } = this.state;
        const {
            singularName,
            requiresVideoPlayer,
            relationsToShow,
            linkOptions
        } = serviceOptions;
        const user = client.get('user');
        let { item } = this.state;

        // Initial load
        if ((itemId !== 'new' && !item) || !schema) {
            return (
                <Jumbotron>
                    <p>Hi there! Loading everything now...</p>
                    <img
                        style={{
                            display: 'block',
                            marginLeft: 'auto',
                            marginRight: 'auto'
                        }}
                        src={Spinner}
                        alt="Loading..."
                    />
                </Jumbotron>
            );
        }

        // We're saving
        if (showSaveProgress) {
            return (
                <Jumbotron>
                    <p>PLEASE WAIT WHILE THE FORM AND FILES ARE SAVED.</p>
                    <img
                        style={{
                            display: 'block',
                            marginLeft: 'auto',
                            marginRight: 'auto'
                        }}
                        src={Spinner}
                        alt="Loading..."
                    />
                </Jumbotron>
            );
        }

        // Just deleted this item, so go back to the gallery for this service
        if (redirectToGallery) {
            return (
                <Redirect
                    to={{
                        pathname: `/${serviceName}/`,
                        state: { from: this.props.location }
                    }}
                />
            );
        }

        // View the item.
        if (!item) {
            // if new item
            if (serviceName === 'users') {
                const password = generate({
                    length: 10,
                    numbers: true
                });
                item = {
                    metadata: {
                        password,
                        confirmPassword: password
                    }
                };
            } else {
                item = { metadata: {} };
            }
        }

        const metadata = item.metadata;
        if (item.email) {
            metadata.email = item.email;
            metadata.title = item.email;
        }

        if (item.firstname) {
            metadata.title = item.firstname;
        }

        if (item.firstname && item.lastname) {
            metadata.title = `${item.firstname} ${item.lastname}`;
        }

        const relations = {};
        relationsToShow.forEach(relationObj => {
            let relation = '';
            let message = '';
            if (relationObj.hasOwnProperty('relation')) {
                relation = relationObj.relation;
                if (relationObj.message && item[relation]) {
                    if (item[relation].length) {
                        message = relationObj.message.replace(
                            '{count}',
                            item[relation].length
                        );

                        const subChannelsCount = item[relation].reduce(
                            (total, current) => {
                                let channelCount = 0;
                                if (current.channels) {
                                    channelCount += current.channels.length;
                                }
                                return channelCount + total;
                            },
                            0
                        );
                        if (subChannelsCount) {
                            message = relationObj.message.replace(
                                '{channels.count}',
                                subChannelsCount
                            );
                        } else if (message.includes('{channels.count}')) {
                            message = '';
                        }
                    }
                }
            } else {
                relation = relationObj;
            }
            if (!relations[relation]) {
                relations[relation] = [];
            }
            const relationsList = {};
            if (item[relation] && item[relation].length) {
                if (!relationsList[relation]) {
                    relationsList[relation] = [];
                }
                relationsList[relation] = relationsList[relation].concat(
                    item[relation]
                );
            }
            relationsToShow.forEach(subRelation => {
                const subRelationName = Object.keys(subRelation).length
                    ? subRelation.relation
                    : subRelation;
                if (item[relation] && item[relation].length) {
                    item[relation].forEach(related => {
                        if (
                            related[subRelationName] &&
                            related[subRelationName].length
                        ) {
                            if (!relationsList[subRelationName]) {
                                relationsList[subRelationName] = [];
                            }
                            relationsList[subRelationName] = relationsList[
                                subRelationName
                            ].concat(related[subRelationName]);
                        }
                    });
                }
            });
            Object.keys(relationsList).forEach(key => {
                relationsList[key].forEach(relatedItem => {
                    relations[key].push(
                        <span
                            key={relatedItem.id}
                            style={{ verticalAlign: 'middle' }}
                        >
                            <img
                                id={relatedItem.id}
                                src={relatedItem.metadata.imageUri}
                                alt={relatedItem.metadata.title}
                                style={{ maxWidth: '100%' }}
                            />
                        </span>
                    );

                    relationsToShow.forEach(relationToShow => {
                        if (
                            relatedItem.hasOwnProperty(relationToShow) &&
                            relatedItem[relationToShow].length
                        ) {
                            relatedItem[relationToShow].forEach(
                                relatedRelatedItem => {
                                    relations[key].push(
                                        <div
                                            key={relatedRelatedItem.id}
                                            style={{
                                                verticalAlign: 'middle',
                                                display: 'table-cell'
                                            }}
                                        >
                                            <img
                                                id={relatedRelatedItem.id}
                                                src={
                                                    relatedRelatedItem.metadata
                                                        .imageUri
                                                }
                                                alt={
                                                    relatedRelatedItem.metadata
                                                        .title
                                                }
                                                style={{ maxWidth: '100%' }}
                                            />
                                        </div>
                                    );
                                }
                            );
                        }
                    });
                });
            });

            if (message.length && !messageAcknowledged) {
                confirmAlert({
                    title: 'Warning',
                    message,
                    buttons: [
                        {
                            label: 'I Understand',
                            onClick: () => this.messageAcknowledged()
                        }
                    ]
                });
            }
        });

        const relationsDisplay = Object.keys(relations).map(relation => {
            return (
                <div key={`${relation}parent-div`}>
                    <span>
                        {`${relation.charAt(0).toUpperCase() +
                            relation.slice(1)}: `}
                    </span>
                    {relations[relation].length ? relations[relation] : 'None'}
                </div>
            );
        });

        let track = null;
        let movie = '';
        let poster = '';
        let captions = '';
        if (requiresVideoPlayer) {
            // if options.requiresVideoPlayer for that case in Service options
            if (itemHighestQualityHLSLink && itemHighestQualityHLSLink.length) {
                movie = itemHighestQualityHLSLink;
            }
            poster = helpers.getThumbUrl(metadata.assets);

            if (metadata.subtitles && metadata.subtitles.length) {
                captions =
                    convertedCaption ||
                    metadata.subtitles[0].file ||
                    metadata.subtitles[0].uri;

                // This is how we can re-allow captions after settings CORS for Thumbnail capture
                if (
                    this.player &&
                    (convertedCaption || metadata.subtitles[0].file)
                ) {
                    this.player.video.video.removeAttribute('crossOrigin');
                }
            }
            track = (
                <track
                    kind="captions"
                    srcLang="en-US"
                    label="English"
                    default
                    src={captions}
                />
            );
        }

        const formContext = {
            linkOptions,
            linkFrom: serviceName,
            onUpdate: this.linkedItemsUpdate
        };

        const widgets = {
            characterCountTextInput: CharacterCountTextInput
        };

        return (
            <Jumbotron>
                <Row>
                    <Col xs={6}>
                        <h3 className="text-left">
                            {itemId !== 'new' ? 'Edit ' : 'Add New '}
                            {singularName.charAt(0).toUpperCase() +
                                singularName.slice(1)}
                            : {metadata.title}
                        </h3>
                    </Col>
                    <Col xs={3} xsOffset={3}>
                        <h5 className="pull-right">
                            Last updated: {this.lastUpdated()}
                        </h5>
                    </Col>
                </Row>
                {formUpdateState === FORMSTATE.UPDATED ? (
                    <Row
                        className="sticky"
                        style={{
                            background:
                                formUpdateState === FORMSTATE.UPDATED
                                    ? 'yellow'
                                    : 'white'
                        }}
                    >
                        <span className="pull-left">
                            You have unsaved changes!
                        </span>
                        <Button
                            bsStyle="primary"
                            className="pull-right"
                            disabled={showSaveProgress}
                            onClick={this.submitForm}
                        >
                            Save {singularName}
                        </Button>
                    </Row>
                ) : null}
                <div className="results">
                    {relationsDisplay}
                    <Row style={{ height: rowHeight }}>
                        {itemId !== 'new' && requiresVideoPlayer && (
                            <Col className="col-xs-7 dad-video-player">
                                <div style={{ background: 'white' }}>
                                    <div
                                        style={{
                                            width: columnWidth,
                                            margin: '10px auto'
                                        }}
                                    >
                                        <Player
                                            playsInLine
                                            fluid={false}
                                            width={columnWidth}
                                            poster={poster}
                                            src={movie}
                                            ref={this.setupPlayer}
                                            preload="metadata"
                                        >
                                            <Shortcut
                                                clickable={false}
                                                shortcuts={
                                                    this.videoPlayerShortcuts
                                                }
                                            />
                                            <ControlBar
                                                autoHide={false}
                                                disableDefaultControls
                                            >
                                                <PlayToggle key="play-toggle" />
                                                <VolumeControl />
                                                <ForwardMinutesControl
                                                    minutes={5}
                                                    label="5"
                                                    key="forward-5-minutes-control"
                                                />
                                                <ForwardMinutesControl
                                                    minutes={7}
                                                    label="7"
                                                    key="forward-7-minutes-control"
                                                />
                                                <ForwardMinutesControl
                                                    minutes={10}
                                                    label="10"
                                                    key="forward-10-minutes-control"
                                                />
                                                <CurrentTimeDisplay key="current-time-display" />
                                                <TimeDivider key="time-divider" />
                                                <DurationDisplay key="duration-display" />
                                                <ProgressControl key="progress-control" />
                                                <PreviousAdBreakControl
                                                    position={previousAdBreak}
                                                    key="previous-ad-break"
                                                />
                                                <NextAdBreakControl
                                                    position={nextAdBreak}
                                                    key="next-ad-break"
                                                />
                                            </ControlBar>
                                            {track}
                                        </Player>
                                    </div>
                                </div>
                                <div className="video-toolbar">
                                    <div>
                                        <button
                                            className="pull-left btn-sm btn btn-primary"
                                            onClick={this.addThumbnail}
                                        >
                                            + Thumbnail
                                        </button>
                                        {item && item.metadata.subtitles && (
                                            <button
                                                onClick={this.handleAdjuster}
                                                className={`btn-sm btn  pull-left ${
                                                    showAdjuster
                                                        ? 'active btn-success'
                                                        : 'btn-primary'
                                                }`}
                                            >
                                                {showAdjuster
                                                    ? 'Hide CC adjust'
                                                    : 'Show CC adjust'}
                                            </button>
                                        )}
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexWrap: 'wrap',
                                                justifyContent: 'flex-end'
                                            }}
                                        >
                                            <em>Ad Breaks:</em>
                                            <PauseAtAdBreaks
                                                onChange={
                                                    this.handlePauseAdBreak
                                                }
                                            />
                                            <CopyToClipboard
                                                text={formattedAdBreaks}
                                                onCopy={() =>
                                                    this.flashSuccessButton(
                                                        'adBreakCopy'
                                                    )
                                                }
                                            >
                                                <button
                                                    className={`btn-sm btn ${adBreakCopyStyle}`}
                                                    id="adBreakCopy"
                                                >
                                                    Copy
                                                </button>
                                            </CopyToClipboard>
                                            <CopyToClipboard
                                                text={formattedAdBreaksSorted}
                                                onCopy={() =>
                                                    this.flashSuccessButton(
                                                        'adBreakSortedCopy'
                                                    )
                                                }
                                            >
                                                <button
                                                    className={`btn-sm btn ${adBreakSortedCopyStyle}`}
                                                    id="adBreakSortedCopy"
                                                >
                                                    Copy Sorted
                                                </button>
                                            </CopyToClipboard>
                                            <button
                                                className="btn-sm btn btn-primary"
                                                onClick={this.addAdBreak}
                                            >
                                                +
                                            </button>
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            marginTop: 10,
                                            display: 'flex'
                                        }}
                                    >
                                        <div>
                                            {showAdjuster && (
                                                <ClosedCaptions
                                                    className="pull-left"
                                                    captionFile={
                                                        convertedCaption
                                                    }
                                                    captionAdjuster={
                                                        itemCaptionAdjuster
                                                    }
                                                    playerCurrentTime={
                                                        playerCurrentTime
                                                    }
                                                    onNewCaptions={
                                                        this.onNewCaptions
                                                    }
                                                />
                                            )}
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex'
                                        }}
                                    >
                                        {this.state.thumbnailBar}
                                    </div>
                                </div>
                                <div
                                    className="wave-bar"
                                    ref={this.setupPeaks}
                                    onClick={() => {
                                        if (
                                            this.player &&
                                            this.player.video &&
                                            this.player.video.video
                                        ) {
                                            this.player.video.video.focus();
                                        }
                                    }}
                                ></div>
                                <div
                                    className="wave-overview-bar"
                                    id="overview-container"
                                    onClick={() => {
                                        if (
                                            this.player &&
                                            this.player.video &&
                                            this.player.video.video
                                        ) {
                                            this.player.video.video.focus();
                                        }
                                    }}
                                ></div>
                            </Col>
                        )}
                        <Col
                            className={
                                requiresVideoPlayer ? 'col-xs-5' : 'col-xs-12'
                            }
                            style={{ overflowY: 'scroll', height: rowHeight }}
                        >
                            <Form
                                schema={schema}
                                additionalMetaSchemas={[additionalMetaSchemas]}
                                uiSchema={uiSchema}
                                fields={fields}
                                formContext={formContext}
                                formData={metadata}
                                onChange={this.onChange}
                                onSubmit={this.saveItem}
                                onError={this.onError}
                                showErrorList={false}
                                liveValidate
                                enctype="multipart/form-data"
                                widgets={widgets}
                                validate={this.validateUserData}
                            >
                                <button
                                    ref={button => {
                                        this.submitButton = button;
                                    }}
                                    style={{ display: 'none' }}
                                />
                            </Form>
                        </Col>
                    </Row>
                    {/* TODO: need to move the DDC component to a separate component from Linker so I can use it here}
                        {serviceName === 'playlists' &&
                        <DragDropContext
                        onDragStart={this.onDragStart}
                        onDragEnd={this.onDragEnd}
                        >
                        <h4 style={{textAlign: 'center'}}>Drag to set order:</h4>
                        <SingleList
                        items={editableItems}
                        onRemoveItem={this.removeItem}
                        dndEnabled={true}
                        {...this.props}
                        />
                        </DragDropContext>
                        }
                        */}

                    <Row>
                        <Button
                            bsStyle="primary"
                            className="pull-right spaced"
                            disabled={showSaveProgress}
                            onClick={this.submitForm}
                        >
                            Save {singularName}
                        </Button>
                        {user.email === 'me@nabeards.com' &&
                            serviceName === 'channels' && (
                                <>
                                    <Button
                                        bsStyle="info"
                                        className="pull-right spaced"
                                        onClick={this.publishFeed}
                                    >
                                        Publish Feed
                                    </Button>
                                    {/* <Button */}
                                    {/*    bsStyle="info" */}
                                    {/*    className="pull-right spaced" */}
                                    {/*    onClick={this.generateFeedSheet} */}
                                    {/* > */}
                                    {/*    Download Feed Excel */}
                                    {/* </Button> */}
                                    <Button
                                        bsStyle="success"
                                        className="pull-right spaced"
                                        onClick={this.generateFeed}
                                    >
                                        Generate Feed
                                    </Button>
                                </>
                            )}
                        {itemId !== 'new' && (
                            <Button
                                bsStyle="danger"
                                className="pull-left"
                                disabled={showSaveProgress}
                                onClick={this.confirmDelete}
                            >
                                Delete {singularName}
                            </Button>
                        )}
                    </Row>
                </div>
            </Jumbotron>
        );
    }
}
