import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Header from './components/header';
import Footer from './components/footer';

import './style/layout.css';

export default function Layout({ children }) {
    return (
        <Router>
            <div id="page-container">
                <div id="content-wrap">
                    <div className="full-height">
                        <Header />
                        {children}
                    </div>
                </div>
                <Footer id="footer" />
            </div>
        </Router>
    );
}
