import React from 'react';
import TimeFormat from 'hh-mm-ss';

class ClosedCaptions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cue1TimeStamp: 0,
            cue2TimeStamp: 0,
            cue1Id: 1,
            cue2Id: 2
        };
    }

    getCue1TimeStamp = () => {
        this.setState({
            cue1TimeStamp: this.props.playerCurrentTime
        });
    };

    getCue2TimeStamp = () => {
        this.setState({
            cue2TimeStamp: this.props.playerCurrentTime
        });
    };

    setCue1Id = ({ target }) => {
        this.setState({
            cue1Id: target.value
        });
    };

    setCue2Id = ({ target }) => {
        this.setState({
            cue2Id: target.value
        });
    };

    shiftCues = () => {
        const { cue1Id, cue1TimeStamp } = this.state;
        const { captionAdjuster, onNewCaptions } = this.props;

        try {
            captionAdjuster.move(cue1Id, Math.round(cue1TimeStamp * 1000));

            if (onNewCaptions) {
                onNewCaptions(captionAdjuster.toString());
            }
        } catch (error) {
            alert(error);
        }
    };

    smartShift = () => {
        const { cue1Id, cue2Id, cue1TimeStamp, cue2TimeStamp } = this.state;
        const { captionAdjuster, onNewCaptions } = this.props;

        try {
            captionAdjuster.move(
                cue1Id,
                Math.round(cue1TimeStamp * 1000),
                cue2Id,
                Math.round(cue2TimeStamp * 1000)
            );

            if (onNewCaptions) {
                onNewCaptions(captionAdjuster.toString());
            }
        } catch (error) {
            alert(error);
        }
    };

    render() {
        return (
            <div>
                <p
                    style={{
                        marginTop: 20,
                        textAlign: 'left',
                        fontSize: '2rem'
                    }}
                >
                    Start cue
                </p>
                <div style={{ display: 'flex' }}>
                    <select
                        onChange={this.setCue1Id}
                        style={{ width: 100 }}
                        id="cue1"
                        name="cues"
                    >
                        <option>Video cues</option>
                        {this.props.captionAdjuster &&
                            this.props.captionAdjuster.cues.map(
                                (cue, index) => (
                                    <option value={cue.id} key={index}>
                                        {`${TimeFormat.fromMs(
                                            cue.start,
                                            'hh:mm:ss.sss'
                                        )} ${cue.text}`}
                                    </option>
                                )
                            )}
                    </select>

                    <button
                        onClick={this.getCue1TimeStamp}
                        className="btn-sm btn btn-primary"
                    >
                        Display timestamp
                    </button>
                    <input
                        value={TimeFormat.fromS(
                            this.state.cue1TimeStamp,
                            'hh:mm:ss.sss'
                        )}
                        id="cue1TimeStamp"
                        type="text"
                        placeholder="00:00:00.000"
                        size="10"
                    />
                    <button
                        onClick={this.shiftCues}
                        className="btn-sm btn btn-primary"
                    >
                        Shift Cue
                    </button>
                </div>

                <p
                    style={{
                        textAlign: 'left',
                        marginTop: 20,
                        fontSize: '2rem'
                    }}
                >
                    End cue
                </p>
                <div style={{ display: 'flex' }}>
                    <select
                        onChange={this.setCue2Id}
                        style={{ width: 100 }}
                        id="cue2"
                        name="cues"
                    >
                        <option>Video cues</option>
                        {this.props.captionAdjuster &&
                            this.props.captionAdjuster.cues.map(
                                (cue, index) => (
                                    <option value={cue.id} key={index}>
                                        {`${TimeFormat.fromMs(
                                            cue.start,
                                            'hh:mm:ss.sss'
                                        )} ${cue.text}`}
                                    </option>
                                )
                            )}
                    </select>

                    <button
                        onClick={this.getCue2TimeStamp}
                        className="btn-sm btn btn-primary"
                    >
                        Display timestamp
                    </button>

                    <input
                        value={TimeFormat.fromS(
                            this.state.cue2TimeStamp,
                            'hh:mm:ss.sss'
                        )}
                        id="cue2TimeStamp"
                        type="text"
                        placeholder="00:00:00.000"
                        size="10"
                    />
                    <button
                        onClick={this.smartShift}
                        className="btn-sm btn btn-primary"
                    >
                        Smart shift
                    </button>
                </div>
            </div>
        );
    }
}

export default ClosedCaptions;
