import React, { Component } from 'react';
import { Button, Grid } from 'react-bootstrap';
import queryString from 'query-string';
import './login.css';
import client from '../../Feathers';

class Verify extends Component {
    constructor(props) {
        super(props);
        const { email, token, type } = queryString.parse(
            window.location.search
        );
        this.state = {
            email,
            token,
            type
        };
    }

    backToLogin = () => {
        const { email } = this.state;

        window.location.replace(`/?email=${email}`);
    };

    verify = async () => {
        const { token } = this.state;

        try {
            return await this.sendToken('verifySignupLong', token);
        } catch (err) {
            if (err.message.includes('No record found for')) {
                alert('Sorry you can not do this!');
            } else {
                return err;
            }
        }
    };

    sendToken = (action, value) =>
        client.service('authManagement').create({ action, value }, {});

    render() {
        const { email, token, type } = this.state;
        if (type === 'verify') {
            this.sendToken('verifySignupLong', token)
                .then(() => {
                    this.sendToken('sendResetPwd', { email });
                })
                .catch(err => {
                    console.log(err);
                });
        }

        return (
            <Grid>
                <h3 className="text-center">
                    Thank you for confirming your account.
                </h3>
                <h4 className="text-center">
                    Please check your email to set your password.
                </h4>
                <Button
                    className="col-md-4 col-md-offset-4"
                    type="submit"
                    bsSize="large"
                    onClick={this.backToLogin}
                >
                    Back to Login
                </Button>
            </Grid>
        );
    }
}

export default Verify;
