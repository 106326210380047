import React, { Component } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import ServiceOptions from '../ServiceOptions';
import ItemGalleryPage from '../ItemGallery/item-gallery-page';
import { IconButton, SingleList } from './components';
import { Row, Col } from 'react-bootstrap';
import helpers from '../helpers';

function reorder(list, startIndex, endIndex) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
}

export default class ItemLinker extends Component {
    constructor(props) {
        super(props);

        const mediaGalleryProps = {
            name: 'media',
            handleThumbnailClick: item => this.addItem('media', item)
        };
        const productsGalleryProps = {
            name: 'products',
            handleThumbnailClick: item => this.addItem('products', item)
        };

        const noOp = param => {
            // do nothing
            console.log('no op on', param);
        };

        const { formContext } = props;
        const linkFromService = formContext.linkFrom;
        const linkToService = props.name;

        let requiresOrder = true;
        if (formContext.linkOptions) {
            if (formContext.linkOptions.media) {
                if (formContext.linkOptions.media.filter) {
                    mediaGalleryProps.filter =
                        formContext.linkOptions.media.filter;
                }
            }
            if (formContext.linkOptions.products) {
                if (formContext.linkOptions.products.filter) {
                    productsGalleryProps.filter =
                        formContext.linkOptions.products.filter;
                }
            }
        }

        const selectedItems = props.formData || [];
        const onUpdate = formContext.onUpdate || noOp;
        const linkFromServiceOptions = new ServiceOptions(
            linkFromService,
            'gallery'
        );
        const linkToServiceOptions = new ServiceOptions(
            linkToService,
            'gallery'
        );
        const mediumGalleryProps = { ...this.props, ...mediaGalleryProps };
        const productGalleryProps = { ...this.props, ...productsGalleryProps };

        this.state = {
            selectedItems,
            linkFromService,
            linkToService,
            onUpdate,
            linkFromServiceOptions,
            linkToServiceOptions,
            mediumGalleryProps,
            productGalleryProps,
            requiresOrder,
            isMediumGalleryVisible: false,
            isProductGalleryVisible: false,
            seasonChosen: 0
        };
    }

    updateItems = (items, service) => {
        const { onUpdate } = this.state;
        if (!service) {
            service = 'playlist';
        }
        this.setState({ selectedItems: items });
        onUpdate({
            serviceName: service,
            items: items
        });
    };

    addItem = (service, item) => {
        const { selectedItems } = this.state;

        // Don't add it if it's already there
        const isInList = selectedItems.some(
            selectedItem => item.id === selectedItem.id
        );
        if (!isInList) {
            if (!item[service + 'Playlists']) {
                item[service + 'Playlists'] = {};
            }
            selectedItems.push(item);
            this.updateItems(selectedItems, service);
        }
    };

    removeItem = itemId => {
        let { selectedItems } = this.state;
        selectedItems.some((item, index) => {
            if (item.id === itemId) {
                selectedItems.splice(index, 1);
                return true;
            }
            return false;
        });
        this.updateItems(selectedItems);
    };

    onDragStart = () => {
        // Add a little vibration if the browser supports it.
        if (window.navigator.vibrate) {
            window.navigator.vibrate(100);
        }
    };

    onDragEnd = result => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        const items = reorder(
            this.state.selectedItems,
            result.source.index,
            result.destination.index
        );

        this.updateItems(items);
    };

    render() {
        const {
            linkToService,
            selectedItems,
            requiresOrder,
            mediumGalleryProps,
            productGalleryProps,
            isMediumGalleryVisible,
            isProductGalleryVisible
        } = this.state;

        const editableItems = selectedItems.map(item => {
            const thumbnail = helpers.getThumbUrl(
                item.metadata.assets,
                item.metadata.imageUri
            );
            return {
                thumbnail,
                title: item.metadata.title,
                id: item.id,
                service: linkToService
            };
        });

        return (
            <div style={{ border: '1px solid black' }}>
                <legend id={'legend-' + linkToService}>
                    {linkToService.charAt(0).toUpperCase() +
                        linkToService.slice(1)}
                </legend>
                <DragDropContext
                    onDragStart={this.onDragStart}
                    onDragEnd={this.onDragEnd}
                >
                    {requiresOrder && (
                        <h4 style={{ textAlign: 'center' }}>
                            Drag to set order:
                        </h4>
                    )}
                    <SingleList
                        items={editableItems}
                        onRemoveItem={this.removeItem}
                        dndEnabled={requiresOrder}
                        {...this.props}
                    />
                </DragDropContext>
                <Row>
                    <Col xs={3} className="text-left">
                        <IconButton
                            type="info"
                            icon={isMediumGalleryVisible ? 'minus' : 'plus'}
                            text="Media"
                            className="btn-add col-xs-12"
                            tabIndex="0"
                            onClick={() => {
                                const stateUpdate = {
                                    isMediumGalleryVisible: false
                                };
                                if (!isMediumGalleryVisible) {
                                    stateUpdate.isMediumGalleryVisible = true;
                                    stateUpdate.isProductGalleryVisible = false;
                                }
                                this.setState(stateUpdate);
                            }}
                        />
                    </Col>
                    <Col xs={3} xsOffset={6} className="text-right">
                        <IconButton
                            type="info"
                            icon={isProductGalleryVisible ? 'minus' : 'plus'}
                            text="Products"
                            className="btn-add col-xs-12"
                            tabIndex="1"
                            onClick={() => {
                                const stateUpdate = {
                                    isProductGalleryVisible: false
                                };
                                if (!isProductGalleryVisible) {
                                    stateUpdate.isProductGalleryVisible = true;
                                    stateUpdate.isMediumGalleryVisible = false;
                                }
                                this.setState(stateUpdate);
                            }}
                        />
                    </Col>
                </Row>
                {isMediumGalleryVisible && (
                    <ItemGalleryPage {...mediumGalleryProps} />
                )}
                {isProductGalleryVisible && (
                    <ItemGalleryPage {...productGalleryProps} />
                )}
            </div>
        );
    }
}
