import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import client from './Feathers';

import Layout from './Layout/layout';

import LandingPage from './LandingPage/components/landingPage';
import Browse from './Browse/browse';
import ItemViewer from './ItemViewer/item-viewer';
import ItemGallery from './ItemGallery/item-gallery';
import Batch from './Batch/batch';

import 'react-table/react-table.css';
import './styles/bootstrap.min.css';
import './styles/bootstrapOverrides.css';
import './App.css';

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            client.get('isAuthed') ? (
                <Component {...props} />
            ) : (
                <Redirect to="/" />
            )
        }
    />
);

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isAuthRun: false,
            isAuthed: false,
            authId: null
        };
        client
            .reAuthenticate()
            .then(() => this.setState({ isAuthRun: true }))
            .catch(err => {
                this.setState({ isAuthRun: true });
                console.error(err);
            });
    }

    componentDidMount() {
        client.on('authenticated', async res => {
            const info = await client.get('authentication');
            this.setState({ authId: info.user.id });
            client.set('user', info.user);
            client.emit('userSet', {});
            client.set('isAuthed', true);
            this.setState({ isAuthed: true });
        });
        client.on('logout', () => {
            client.set('isAuthed', false);
            this.setState({ isAuthed: false });
        });
    }

    render() {
        return (
            <Layout>
                {this.state.isAuthRun ? (
                    <Switch>
                        <Route
                            exact
                            path="/"
                            render={() =>
                                this.state.isAuthed ? (
                                    <Redirect to="/browse" />
                                ) : (
                                    <LandingPage />
                                )
                            }
                        />
                        <PrivateRoute
                            name="browse"
                            path="/browse"
                            component={Browse}
                        />
                        <PrivateRoute
                            name="batch"
                            path="/batch"
                            component={Batch}
                        />
                        <PrivateRoute
                            name="viewer"
                            path="/:serviceName/:id"
                            component={ItemViewer}
                        />
                        <PrivateRoute
                            name="gallery"
                            path="/:serviceName"
                            component={ItemGallery}
                        />
                        <Redirect to="/" />
                    </Switch>
                ) : null}
            </Layout>
        );
    }
}

export default App;
