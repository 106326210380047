import passwordValidator from 'password-validator';
require('dotenv').config();

const schema = new passwordValidator()
    .is()
    .min(8) // Minimum length 8
    .is()
    .max(100) // Maximum length 100
    .has()
    .uppercase() // Must have uppercase letters
    .has()
    .lowercase() // Must have lowercase letters
    .has()
    .digits(); // Must have digits

const config = {
    backendUrl:
        process.env.REACT_APP_MOM ||
        'https://media-backend.hatchfarmstudios.com'
};

export { config, schema };
