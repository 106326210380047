import PropTypes from 'prop-types';
import React, { Component } from 'react';

const propTypes = {
    actions: PropTypes.object,
    className: PropTypes.string,
    position: PropTypes.number
};

const defaultProps = {
    position: 0
};

export default mode => {
    class NextPreviousAdBreakControl extends Component {
        constructor(props, context) {
            super(props, context);
            this.handleClick = this.handleClick.bind(this);
        }

        handleClick() {
            const { actions, position } = this.props;
            if (position !== 0) {
                actions.seek(position);
            }
        }

        render() {
            const { className, position } = this.props;
            const classNames = [
                'video-react-time-control',
                'video-react-control'
            ];
            const direction = mode === 'next' ? '›' : '‹';
            if (className) {
                classNames.push(className);
            }

            return (
                <div
                    ref={c => {
                        this.button = c;
                    }}
                    className={classNames.join(' ')}
                    onClick={this.handleClick}
                    style={{ cursor: 'pointer' }}
                >
                    <div className="video-react-current-time-display">
                        {position !== 0 ? direction : ''}
                    </div>
                </div>
            );
        }
    }

    NextPreviousAdBreakControl.propTypes = propTypes;
    NextPreviousAdBreakControl.defaultProps = defaultProps;
    return NextPreviousAdBreakControl;
};
